import React from 'react';
import SpaBanner from './SpaSection/SpaBanner';
import SpaContentPagination from './SpaSection/SpaContentPagination';

function SpaPagination(props) {
    return  (
    <div>
        <SpaBanner />
        <SpaContentPagination exampleProp={props}/>
    </div>
    )
}

export default SpaPagination;
