import React from 'react';

function DetailContentSidebar()
{
    return (
      <div>
        <div className="RightMeta p-4 mt-4">
          <h4 className="text-black">NEED HELP BOOKING? </h4>
          <p>We take great pride in being in your  24/7 one point contact. Just send us a message and we will help you make the planning process effortless and fast. </p>
        </div>
        <div className="RightMeta p-4 mt-4">
          <h4 className="text-black">WHY BOOK WITH US? </h4>
          <p> 
           <strong>Reliable & Trustworthy</strong><br />
            Our clients know they can trust us with their schedule and safety. We value your time.<br />
            <br />
            <strong>Flexibility</strong><br />
            We understand that things come up. We strive to be flexible with your needs and provide solutions. <br />
            <br />
            <strong>Range of Activities</strong><br />
            Choose from a wide range of spas, parties, and restaurants. Enjoy a good balance of lifestyle and culture. <br />
            <br />
            <strong>5 Star Accommodations</strong><br />
            Partnered with top fully staffed, luxury villas and hotels. Enjoy amazing decor that inspires. Feel at home. <br />
            <br />
            <strong>We Save You Time</strong><br />
            Book your flight and we will take care of the rest. We make it effortless. 
         </p>
        </div>
      </div>
    )
}

export default DetailContentSidebar;