import React from 'react';
import BlogBanner from './BlogSection/BlogBanner';
import BlogContentPagination from './BlogSection/BlogContentPagination';

function BlogPagination(props) {
    return  (
    <div>
        <BlogBanner />
        <BlogContentPagination exampleProp={props}/>
    </div>
    )
}

export default BlogPagination;
