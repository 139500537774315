import React from 'react';
import SpaBanner from './SpaSection/SpaBanner';
import SpaContent from './SpaSection/SpaContent';

function Spas() {
    return  (
    <div>
        <SpaBanner />
        <SpaContent />
    </div>
    )
}

export default Spas;
