import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import SpaDetailBreadCrumb from './SpaDetails/SpaDetailBreadCrumb';
import SpaDetailBanner from './SpaDetails/SpaDetailBanner';
import SpaDetailTabs from './SpaDetails/SpaDetailTabs';
import DetailContentSidebar from './DetailContentSidebar';
import SpaDetailReserveForm from './SpaDetails/SpaDetailReserveForm';
import { Helmet } from 'react-helmet'

function SpaDetail(props)
{
    const history = useHistory();
    const [spa, setSpa] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const spa_slug = props.match.params.slug;
        axios.get(`/api/spa/${spa_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setSpa(res.data.spa);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [props.match.params.slug, history]);

    return (
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`Spa - ${spa.title}`}</title>
            <meta name="description" content={spa.meta_keywords} />
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
            <link rel="canonical" href="https://marrakechconcierge.co.uk/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={spa.meta_title} />
            <meta property="og:description" content={spa.meta_keywords} />
            <meta property="og:url" content={`https://marrakechconcierge.co.uk/spa/${spa.slug}`} />
            <meta property="og:site_name" content="Marrakech Concierge | Luxury Tailor-Made Holidays in Marrakech" />
            <meta property="og:image" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:secure_url" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={spa.meta_title} />
            <meta property="og:image:type" content="image/jpg" />
            <link rel="apple-touch-icon"  href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
        </Helmet>
         	<SpaDetailBreadCrumb exampleProp={props} />
            <section className="DetailSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-8"> 
                    <SpaDetailBanner exampleProp={props} />
                    <SpaDetailTabs exampleProp={props} />
                  </div>
                  <div className="col-md-4 mt-4"> 
                    <SpaDetailReserveForm exampleProp={props} />
                    <DetailContentSidebar />
                  </div>
                </div>
              </div>
            </section>
        </div>
    )
}

export default SpaDetail;