import React from 'react';
import BlogBanner from './BlogSection/BlogBanner';
import BlogContent from './BlogSection/BlogContent';

function Blogs() {
    return  (
    <section className="BlogPage">
        <BlogBanner />
        <BlogContent />
    </section>
    )
}

export default Blogs;
