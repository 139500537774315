import Home from '../components/Home';
import Villas from '../components/Villas';
import VillaDetail from '../components/VillaDetail';
import Restaurants from '../components/Restaurants';
import RestaurantDetail from '../components/RestaurantDetail';
import RestaurantPagination from '../components/RestaurantPagination';
// import Hotels from '../components/Hotels';
// import HotelDetail from '../components/HotelDetail';
// import HotelPagination from '../components/HotelPagination';
import Parties from '../components/Parties';
import PartyPagination from '../components/PartyPagination';
import PartyDetail from '../components/PartyDetail';
import PageDetail from '../components/PageDetail';
import Golfs from '../components/Golfs';
import GolfDetail from '../components/GolfDetail';
import GolfPagination from '../components/GolfPagination';
import Spas from '../components/Spas';
import SpaDetail from '../components/SpaDetail';
import SpaPagination from '../components/SpaPagination';
import Blogs from '../components/Blogs';
import BlogDetail from '../components/BlogDetail';
import BlogPagination from '../components/BlogPagination';
import VillaType from '../components/VillaType';
import VillaTypePagination from '../components/VillaTypePagination';
import CustomItinerary from '../components/CustomItinerary';
import Contact from '../components/Contact';
import FastTrack from '../components/FastTrack';
import FastTrackDetail from '../components/FastTrackDetail';
import Cart from '../components/Cart';
import Checkout from '../components/Checkout';
import PaymentSuccess from '../components/PaymentSuccess';
import FastTrackEditDetail from '../components/FastTrackEditDetail';
import Excursions from '../components/Excursions'
import SearchResult from '../components/SearchResult'

const PublicRouteList = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/villas/', exact: true, name: 'Villas', component: Villas },
    { path: '/cart/', exact: true, name: 'Cart', component: Cart },
    { path: '/checkout/', exact: true, name: 'Checkout', component: Checkout },
    { path: '/excursions/', exact: true, name: 'Excursions', component: Excursions },
    { path: '/search/:module/:slug/', exact: true, name: 'SearchResult', component: SearchResult },
    { path: '/search/:module/', exact: true, name: 'SearchResult', component: SearchResult },
    { path: '/payment/success/', exact: true, name: 'PaymentSuccess', component: PaymentSuccess },
    { path: '/villa-type/:villa_type/', exact: true, name: 'VillaType', component: VillaType },
    { path: '/villa-type/:villa_type/page/:page_number/', exact: true, name: 'VillaTypePagination', component: VillaTypePagination },
    { path: '/villa/:slug/', exact: true, name: 'VillaDetail', component: VillaDetail },
    { path: '/restaurants/', exact: true, name: 'Restaurants', component: Restaurants },
    { path: '/restaurants/page/:page_number/', exact: true, name: 'RestaurantPagination', component: RestaurantPagination },
    { path: '/restaurant/:slug/', exact: true, name: 'RestaurantDetail', component: RestaurantDetail },
    // { path: '/hotels', exact: true, name: 'Hotels', component: Hotels },
    // { path: '/hotels/page/:page_number', exact: true, name: 'HotelPagination', component: HotelPagination },
    // { path: '/hotel/:slug', exact: true, name: 'HotelDetail', component: HotelDetail },
    { path: '/golfs/', exact: true, name: 'Golfs', component: Golfs },
    { path: '/golfs/page/:page_number/', exact: true, name: 'GolfPagination', component: GolfPagination },
    { path: '/golf/:slug/', exact: true, name: 'GolfDetail', component: GolfDetail },
    { path: '/spas/', exact: true, name: 'Spas', component: Spas },
    { path: '/spas/page/:page_number/', exact: true, name: 'SpaPagination', component: SpaPagination },
    { path: '/spa/:slug/', exact: true, name: 'SpaDetail', component: SpaDetail },
    { path: '/blogs/', exact: true, name: 'Blogs', component: Blogs },
    { path: '/blogs/page/:page_number/', exact: true, name: 'BlogPagination', component: BlogPagination },
    { path: '/blog/:slug/', exact: true, name: 'BlogDetail', component: BlogDetail },
    { path: '/parties/:party_type/', exact: true, name: 'Parties', component: Parties },
    { path: '/:party_type/page/:page_number/', exact: true, name: 'PartyPagination', component: PartyPagination },
    { path: '/party/:slug/', exact: true, name: 'PartyDetail', component: PartyDetail },
    { path: '/personalised-itinerary/', exact: true, name: 'CustomItinerary', component: CustomItinerary },
    { path: '/fast-track/', exact: true, name: 'FastTrack', component: FastTrack },
    { path: '/contact/', exact: true, name: 'Contact', component: Contact },
    { path: '/:page_slug/', exact: true, name: 'PageDetail', component: PageDetail },
    { path: '/product/:slug/', exact: true, name: 'FastTrackDetail', component: FastTrackDetail },
    { path: '/product/:slug/edit-cart/', exact: true, name: 'FastTrackEditDetail', component: FastTrackEditDetail },
];

export default PublicRouteList;