import Slider from "react-slick";
import React, {useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import feedQuoteBgImage from '../../assets/img/quote.png';
import axios from 'axios';

const Testimonial = ({ initialSlide = 0 }) => {

	const [loading, setLoading] 				= useState(true);
    const [testimoniallist, setTestimoniallist] = useState([]);
	const [hasSetPosition, setHasSetPosition] 	= useState(false);
    const slider 								= useRef();
    const [isExpanded, setIsExpanded]           = useState(false);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/testimonial`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setTestimoniallist(res.data.testimonial)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    useEffect(() => {
        if (slider.current && !hasSetPosition) {
          slider.current.slickGoTo(initialSlide);
          setHasSetPosition(true);
        }
    }, [initialSlide, hasSetPosition, slider]);
    const settings = {
        infinite: true,
        speed: 3000,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: false,
        focusOnSelect: true,
        touchMove: true,
        adaptiveHeight: true,
      	initialSlide,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
     
    };
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
      };
    var testimonialHTML = "";
    if(loading)
    {
        return <h4>Loading...</h4>
    }
    else
    {
        testimonialHTML = 
        testimoniallist.map( (item) => {
            const words = item.review.split(' ');
            const truncatedText = isExpanded ? item.review : words.slice(0, 50).join(' ');
            const shouldDisplayMoreLink = words.length > 50;
            return (
            <div key={item.id} className="item">
                <div className="Holder">
                    <div  className={`ReviewDesc ${isExpanded ? 'expanded' : ''}`}>
                        <p>
                            {truncatedText}
                            {shouldDisplayMoreLink && (
                                <Link to="#" onClick={toggleReadMore} className="btnLogoColor">
                                    {isExpanded ? '..Less' : '..More'}
                                </Link>
                            )}
                        </p>
                    </div>
                    <div className="ReviewMeta">
                    	<span className="ReviewFrom">--{item.name}</span>
                    	<span className="ReviewBy">{item.company}</span>
                    </div>
                </div>
            </div>
            )
        });
    }

    return  (
	   	<section className="FeedbackSection">
	        <div className="container">
	            <div className="feed-quote" style={{backgroundImage: `url(${feedQuoteBgImage})` }}></div>
	            <div className="Testimonials">
	                <h2>What Clients Say?</h2>
	                <div className="FrontReviewSlider">
		                <Slider ref={slider} {...settings}>
		                   {testimonialHTML}
		                </Slider>
	                </div>
	            </div>
	        </div>
	    </section>
    )
}

export default Testimonial;
