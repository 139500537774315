import React from 'react';
import GolfDetailBreadCrumb from './GolfDetails/GolfDetailBreadCrumb';
import GolfDetailBanner from './GolfDetails/GolfDetailBanner';
import GolfDetailTabs from './GolfDetails/GolfDetailTabs';
import GolfDetailReserveForm from './GolfDetails/GolfDetailReserveForm';
import DetailContentSidebar from './DetailContentSidebar';

function GolfDetail(props)
{
    return (
        <div>
         	<GolfDetailBreadCrumb exampleProp={props} />
            <section className="DetailSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-8"> 
                    <GolfDetailBanner exampleProp={props} />
                    <GolfDetailTabs exampleProp={props} />
                  </div>
                  <div className="col-md-4 mt-4"> 
                    <GolfDetailReserveForm exampleProp={props} />
                    <DetailContentSidebar />
                  </div>
                </div>
              </div>
            </section>
            
        </div>
    )
}

export default GolfDetail;