import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function PartyDetailTabs(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [party, setParty] = useState([]);
 
    useEffect(() => {
      let isMounted = true;
      const party_slug = exampleProp.match.params.slug;
      axios.get(`/api/party/${party_slug}`).then(res=>{
          if(isMounted)
          {
              if(res.data.status === 200)
              {
                  setParty(res.data.party);
              }
          }
      });

      return () => {
          isMounted = false
      };
    }, [exampleProp.match.params.slug, history]);

    return (
    <div className="DetailTab mt-5">
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {party.content && (
            <button 
              className="nav-link active" 
              id="nav-description-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-description" 
              type="button" 
              role="tab" 
              aria-controls="nav-description" 
              aria-selected="true">
                <i className="fa-solid fa-circle-info"></i> 
                <span className="m-2">Description</span>
            </button>
          )}

          {party.location && (
            <button 
              className="nav-link" id="nav-location-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-location" 
              type="button" 
              role="tab" 
              aria-controls="nav-location" 
              aria-selected="false">
                <i className="fa-solid fa-map"></i>
                <span className="m-2">Location</span>
            </button>
          )}

          {party.availability && (
            <button 
              className="nav-link" id="nav-availability-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-availability" 
              type="button" 
              role="tab" 
              aria-controls="nav-availability" 
              aria-selected="false">
                <i className="fa-solid fa-align-justify"></i>
                <span className="m-2">Availability</span>
            </button>
          )}

          {party.facilities && (
            <button 
              className="nav-link" 
              id="nav-facilities-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-facilities" 
              type="button" 
              role="tab" 
              aria-controls="nav-facilities" 
              aria-selected="false">
                <i className="fa-solid fa-rocket"></i> 
                <span className="m-2">Facilities</span>
            </button>
          )}

          {party.things_to_do && (
            <button 
              className="nav-link" 
              id="nav-things-to-do-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-things-to-do" 
              type="button" 
              role="tab" 
              aria-controls="nav-things-to-do" 
              aria-selected="false">
                <i className="fa-solid fa-puzzle-piece"></i> 
                <span className="m-2">Things To Do</span>
            </button>
          )}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        {party.content && (
          <div 
            className="tab-pane fade show active" 
            id="nav-description" 
            role="tabpanel" 
            aria-labelledby="nav-description-tab" 
            tabIndex="0">
            <div className="Info">
              {parse( party.content )}
            </div>
          </div>
        )}
        
        {party.location && (
          <div 
            className="tab-pane fade" 
            id="nav-location" 
            role="tabpanel" 
            aria-labelledby="nav-location-tab" 
            tabIndex="0">
              <div className="Location">
                {parse( party.location )}
                {party.map && (
                  <div className="mt-5">
                    {parse(party.map)}
                  </div>
                )}
              </div>
          </div>
        )}

        {party.availability && (
          <div 
            className="tab-pane fade" 
            id="nav-availability" 
            role="tabpanel" 
            aria-labelledby="nav-availability-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( party.availability )}
              </div>
          </div>
        )}

        {party.facilities && (
          <div 
            className="tab-pane fade" 
            id="nav-facilities" 
            role="tabpanel" 
            aria-labelledby="nav-facilities-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( party.facilities )}
              </div>
          </div>
        )}

        {party.things_to_do && (
          <div 
            className="tab-pane fade" 
            id="nav-things-to-do" 
            role="tabpanel" 
            aria-labelledby="nav-things-to-do-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( party.things_to_do )}
              </div>
          </div>
        )}
      
      </div>
    </div>
    )
}

export default PartyDetailTabs;