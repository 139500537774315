import React from 'react';
import PartyBanner from './PartySection/PartyBanner';
import PartyContent from './PartySection/PartyContent';

function Parties(props) {
    return  (
    <div>
        <PartyBanner exampleProp={props}/>
        <PartyContent exampleProp={props}/>
    </div>
    )
}

export default Parties;
