import React from 'react';
import CustomItineraryBreadCrumb from './CustomItinerary/CustomItineraryBreadCrumb';
import CustomItinerarySlider from './CustomItinerary/CustomItinerarySlider';
import CustomItineraryForm from './CustomItinerary/CustomItineraryForm';

function CustomItinerary() {
    return  (
    <div>
        <CustomItineraryBreadCrumb />
        <section className="PageDetailSection mt-10">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="text-center mt-5">
                    <CustomItinerarySlider />
                    <CustomItineraryForm />
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </section>
    </div>
    )
}

export default CustomItinerary;
