import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

function BlogContent() {

    const [blogList, setBlog]             = useState([]);
    const [pageNumber, setPageNumber]     = useState([]);
    const [currentPage, setcurrentPage]   = useState([]);

    useEffect(() => {
      let isMounted = true;

      axios.get(`/api/blogList`).then(res=>{
          if(isMounted)
          {
              if(res.status === 200)
              {
                  setBlog(res.data.blog);
                  setPageNumber(res.data.page_number);
                  setcurrentPage(res.data.current_page);
              }
          }
      });

      return () => {
          isMounted = false
      };

    }, []);
    const pages = Array.from({ length: pageNumber }, (_, index) => index + 1);
    return  (
      <div className="container">
        <div className="row">
          <div className="col-md-9">
             { blogList.map((item) => ( 
              <div className="Holder py-4 border-bottom">
                  <h2><Link to={`/blog/${item.slug}/`}>{item.title}</Link></h2>
                  <p className="PostMeta"> 
                   {item.author && (
                      <span className="PostBy"><i className="fa-solid fa-user"></i> {item.author}</span> 
                    )}
                    {item.publish_date && (
                      <span className="PostDate"><i className="fa-regular fa-clock"></i> {item.publish_date}</span> 
                    )}
                  </p>
                  {item.featured_image && (
                    <div className="ThumbPic mt-4"> 
                      <img src={item.featured_image} alt={item.title} className="img-fluid w-100" loading="lazy" /> 
                    </div>
                  )}
                  {item.description && (
                  <div className="ShortInfo mt-4">
                    <p>{item.description}</p>
                  </div>
                   )}
              </div>
            ))}
            <div className="PaginationNav mt-5">
              {pageNumber > 1 && (
                <nav>
                  <ul className="pagination">
                    {pages.map((page) => (
                      <li key={page} className={`page-item ${currentPage === page ? 'active' : '' }`} aria-current={page === 1 ? 'page' : undefined}>
                        <Link to={`/blogs/page/${page}/`} className="page-link">
                          {page}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>

          </div>
           <div className="col-md-3 mt-5">
            <div className="RecentPost">
              <h3>Other Posts </h3>
              <ul>
               { blogList.map((item) => ( 
                <li>
                    <Link to={`/blog/${item.slug}/`}>{item.title}</Link> 
                    {item.publish_date && (
                      <span><i className="fa-regular fa-clock"></i> {item.publish_date}</span> 
                    )}
                  </li>
               ))}
              </ul>
            </div>
           </div>
        </div>
      </div>
  )
}

export default BlogContent;
