import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';


function SearchBanner(props) {

    const { exampleProp } = props;
    const history = useHistory();
    
    const [page, setPage] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/page/search`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    return  (
    <section 
        className="Banner IBanner position-relative"
        style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
    >
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> 
            {exampleProp.match.params.slug ? (
                <>
                  Search {exampleProp.match.params.module} for {exampleProp.match.params.slug}
                </>
              ) : (
                <>
                  Search {exampleProp.match.params.module}
                </>
            )}
        </h1>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/search/${exampleProp.match.params.slug}/`} > 
                {exampleProp.match.params.slug ? (
                    <>
                      Search {exampleProp.match.params.module} for {exampleProp.match.params.slug}
                    </>
                  ) : (
                    <>
                      Search {exampleProp.match.params.module}
                    </>
                )}
            </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default SearchBanner;
