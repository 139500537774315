import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function GolfDetailTabs(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [golf, setGolf] = useState([]);
 
    useEffect(() => {
      let isMounted = true;
      const golf_slug = exampleProp.match.params.slug;
      axios.get(`/api/golf/${golf_slug}`).then(res=>{
          if(isMounted)
          {
              if(res.data.status === 200)
              {
                  setGolf(res.data.golf);
              }
          }
      });

      return () => {
          isMounted = false
      };
    }, [exampleProp.match.params.slug, history]);

    return (
      <div className="DetailTab mt-5">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {golf.content && (
              <button 
                className="nav-link active" 
                id="nav-description-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#nav-description" 
                type="button" 
                role="tab" 
                aria-controls="nav-description" 
                aria-selected="true">
                  <i className="fa-solid fa-circle-info"></i> 
                  <golfn className="m-2">Description</golfn>
              </button>
            )}

            {golf.location && (
              <button 
                className="nav-link" id="nav-location-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#nav-location" 
                type="button" 
                role="tab" 
                aria-controls="nav-location" 
                aria-selected="false">
                  <i className="fa-solid fa-map"></i>
                  <golfn className="m-2">Location</golfn>
              </button>
            )}

            {golf.availability && (
              <button 
                className="nav-link" id="nav-availability-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#nav-availability" 
                type="button" 
                role="tab" 
                aria-controls="nav-availability" 
                aria-selected="false">
                  <i className="fa-solid fa-align-justify"></i>
                  <golfn className="m-2">Availability</golfn>
              </button>
            )}

            {golf.facilities && (
              <button 
                className="nav-link" 
                id="nav-facilities-tab" 
                data-bs-toggle="tab" 
                data-bs-target="#nav-facilities" 
                type="button" 
                role="tab" 
                aria-controls="nav-facilities" 
                aria-selected="false">
                  <i className="fa-solid fa-rocket"></i> 
                  <golfn className="m-2">Facilities</golfn>
              </button>
            )}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
         {golf.content && (
            <div 
              className="tab-pane fade show active" 
              id="nav-description" 
              role="tabpanel" 
              aria-labelledby="nav-description-tab" 
              tabIndex="0">
              <div className="Info">
                {parse( golf.content )}
              </div>
            </div>
          )}
          
          {golf.location && (
            <div 
              className="tab-pane fade" 
              id="nav-location" 
              role="tabpanel" 
              aria-labelledby="nav-location-tab" 
              tabIndex="0">
                <div className="Location">
                  {parse( golf.location )}
                  {golf.map && (
                    <div className="mt-5">
                      {parse(golf.map)}
                    </div>
                  )}
                </div>
            </div>
          )}

          {golf.availability && (
            <div 
              className="tab-pane fade" 
              id="nav-availability" 
              role="tabpanel" 
              aria-labelledby="nav-availability-tab" 
              tabIndex="0">
                <div className="Info">
                  {parse( golf.availability )}
                </div>
            </div>
          )}

          {golf.facilities && (
            <div 
              className="tab-pane fade" 
              id="nav-facilities" 
              role="tabpanel" 
              aria-labelledby="nav-facilities-tab" 
              tabIndex="0">
                <div className="Info">
                  {parse( golf.facilities )}
                </div>
            </div>
          )}
        </div>
     </div>
    )
}

export default GolfDetailTabs;