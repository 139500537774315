import React from 'react';
import VillaTypeBanner from './VillaTypeSection/VillaTypeBanner';
import VillaTypeContent from './VillaTypeSection/VillaTypeContent';

function VillaType(props) {
    return  (
    <div>
        <VillaTypeBanner exampleProp={props}/>
        <VillaTypeContent exampleProp={props}/>
    </div>
    )
}

export default VillaType;
