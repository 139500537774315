import React, {useState, useEffect} from 'react';
// import {useHistory,useLocation} from 'react-router-dom';
import axios from 'axios';

function Banner() {

    const [loading, setLoading] = useState(true);
    const [bannerlist, setBannerlist] = useState([]);
 
    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/banner`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setBannerlist(res.data.banner)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);


    var bannerHTML = 
    bannerlist.map( (item) => {
        return (
        <div  key={item.id} className={`carousel-item ${item.active}`}>
        {item.featured_image && (
            <img className="d-block w-100" src={item.featured_image} alt={item.title} loading="lazy"/>
        )}
        </div>
        )
    });
   

    return  (
     !loading && (
        <section className="Banner">
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {bannerHTML}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
            </div>
            <div className="hero-search">
                <div className="hero-search__inner">
                    <p>Luxury Lifestyle Concierge Services</p>
                    <h1>Over 10 Years Experience In Marrakech</h1>
                    <p>Enjoy effortless fun & thrilling activities</p>
                    <form className="example" action="/">
                      <div className="row mt-4 justify-content-center">
                        <div className="col-md-12 d-flex">
                            <select name="module">
                                <option value="villas">Villas</option>
                                <option value="restaurants">Restaurants</option>
                                <option value="night-life">Night Life</option>
                                <option value="day-parties">Day Parties</option>
                                <option value="spas">Spa</option>
                                <option value="golfs">Golf</option>
                            </select>
                            <button type="submit" className="w-25 w-md-75">Get me there</button>
                        </div>
                      </div>
                    </form>
                </div>
            </div>
         </section>

     )
    )
}

export default Banner;
