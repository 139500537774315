import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import VillaDetailBreadCrumb from './VillaDetails/VillaDetailBreadCrumb';
import VillaDetailBanner from './VillaDetails/VillaDetailBanner';
import VillaDetailTabs from './VillaDetails/VillaDetailTabs';
import DetailContentSidebar from './DetailContentSidebar';
import VillaDetailReserveForm from './VillaDetails/VillaDetailReserveForm';
import { Helmet } from 'react-helmet';

function VillaDetail(props)
{
  const history = useHistory();
  const [villa, setVilla] = useState([]);

   useEffect(() => {
        let isMounted = true;
        const villa_slug = props.match.params.slug;
        axios.get(`/api/villa/${villa_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setVilla(res.data.villa);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [props.match.params.slug, history]);

  return (
      <div>
        <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`Villa - ${villa.title}`}</title>
            <meta name="description" content={villa.meta_keywords} />
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
            <link rel="canonical" href="https://marrakechconcierge.co.uk/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={villa.meta_title} />
            <meta property="og:description" content={villa.meta_keywords} />
            <meta property="og:url" content={`https://marrakechconcierge.co.uk/villa/${villa.slug}`} />
            <meta property="og:site_name" content="Marrakech Concierge | Luxury Tailor-Made Holidays in Marrakech" />
            <meta property="og:image" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:secure_url" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={villa.meta_title} />
            <meta property="og:image:type" content="image/jpg" />
            <link rel="apple-touch-icon"  href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
        </Helmet>

       	<VillaDetailBreadCrumb exampleProp={props} />
          <section className="DetailSection">
            <div className="container">
              <div className="row">
                <div className="col-md-8"> 
                  <VillaDetailBanner exampleProp={props} />
                  <VillaDetailTabs exampleProp={props} />
                </div>
                <div className="col-md-4 mt-4"> 
                  <VillaDetailReserveForm exampleProp={props} />
                  <DetailContentSidebar exampleProp={props}/>
                </div>
              </div>
            </div>
          </section>
          
      </div>
  )
}

export default VillaDetail;