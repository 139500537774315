import React from 'react';
import trustPilotImage from '../../assets/img/trust-pilot.png';
import trustPilotCountImage from '../../assets/img/trust-pilot-reviews-count.png';

import googleImage from '../../assets/img/google.png';
import googleCountImage from '../../assets/img/google-reviews-count.png';

import facebookImage from '../../assets/img/facebook.png';
import facebookCountImage from '../../assets/img/facebook-reviews-count.png';


function FeedbackForm() {

    return  (
   		<section className="FeedbackFromSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <a 
                            href="https://uk.trustpilot.com/review/marrakechconcierge.co.uk?languages=all" 
                            target="_blank"
                            rel="noreferrer">
                            <div className="Holder mt-4 feedTrustpilot">
                                <div className="Icon">
                                	<img src={trustPilotImage} alt="trust-pilot" className="img-fluid" loading="lazy" />
                                </div>
                                <span className="ReviewCount">
                                	<img src={trustPilotCountImage} alt="Review Count" loading="lazy"/>
                                </span>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-4">
                        <a 
                            href="https://www.google.co.uk/search?q=marrakech+concierge&ie=UTF-8&oe=UTF-8&hl=en-gb&client=safari#lkt=LocalPoiReviews&trex=m_t:lcl_akp,rc_f:nav,rc_ludocids:16963437187529348131,rc_q:Marrakech%2520Concierge,ru_q:Marrakech%2520Concierge" 
                            target="_blank"
                            rel="noreferrer">
                            <div className="Holder mt-4 feedTrustpilot">
                                <div className="Icon">
                                    <img src={googleImage} alt="Google" className="img-fluid" loading="lazy"/>
                                </div>
                                <span className="ReviewCount">
                                   <img src={googleCountImage} alt="Review Count" loading="lazy"/>
                                </span>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-4">
                        <a 
                            href="https://www.facebook.com/themarrakechconcierge/reviews" 
                            target="_blank"
                            rel="noreferrer">
                            <div className="Holder mt-4 feedTrustpilot">
                                <div className="Icon">
                                    <img src={facebookImage} alt="facebook" className="img-fluid" loading="lazy"/>
                                </div>
                                <span className="ReviewCount">
                                   <img src={facebookCountImage} alt="Review Count" loading="lazy"/>
                                </span>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default FeedbackForm;
