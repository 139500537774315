import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function VillaBanner() {

    const [page, setPage] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/page/villa`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    return  (
   <section 
    className="Banner IBanner position-relative" 
    style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
    >
        <div className="PageHead">
            <h1 className="text-uppercase text-white">{page.title}</h1>
            <span className="text-white">{page.subtitle}</span>
            <ol className="breadcrumb d-block">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/villas/">{page.title}</Link>
              </li>
            </ol>
        </div>
    </section>
    )
}

export default VillaBanner;
