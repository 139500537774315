import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function PartyBanner(props) {

    const { exampleProp } = props;
    const history = useHistory();
    const [partyType, setPartyType] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const party_type_slug = exampleProp.match.params.party_type;
        axios.get(`/api/party_type/${party_type_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setPartyType(res.data.party_type);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [exampleProp.match.params.party_type, history]);

    return  (
    <section 
    className="Banner IBanner position-relative"
    style={partyType.background_image ? { backgroundImage: `url(${partyType.background_image})` } : {}}
    > 
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> {partyType.name} </h1>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/parties/${partyType.slug}/`}> {partyType.name} </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default PartyBanner;
