import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import 'superfish/dist/js/superfish';
// import jRespond from 'jrespond';

const MegaMenu = () => {
    const [loading, setLoading]         = useState(true);
    const [villaList, setVilla]         = useState([]);
    const [windowWidth, setWindowWidth] = useState($(window).width());
    // const [isClicked, setIsClicked]     = useState(true);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/villaList`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                  setVilla(res.data.villa);
                  setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    const handleResize = () => {
        setWindowWidth($(window).width());
    };

    const handleClick = (el) => {
      window.location = el.currentTarget.getAttribute('href');
    };

    const superfish = () => {
        if ($('body').hasClass('device-xl') || $('body').hasClass('device-lg')) {
          menuInvert();
          if (!$().superfish) {
            $('body').addClass('no-superfish');
            return true;
          }

          $('body:not(.side-header) #primary-menu:not(.on-click) > ul, body:not(.side-header) #primary-menu:not(.on-click) > div > ul:not(.dropdown-menu), .top-links:not(.on-click) > ul').superfish({
            popUpSelector: 'ul,.mega-menu-content,.top-link-section',
            delay: 250,
            speed: 350,
            animation: { opacity: 'show' },
            animationOut: { opacity: 'hide' },
            cssArrows: false,
            onShow: function () {
              var megaMenuContent = $(this);
              if (megaMenuContent.find('.grid-container').length > 0) {
                megaMenuContent.find('.grid-container').isotope('layout');
              }
            },
          });

          $('body.side-header #primary-menu:not(.on-click) > ul').superfish({
            popUpSelector: 'ul',
            delay: 250,
            speed: 350,
            animation: { opacity: 'show', height: 'show' },
            animationOut: { opacity: 'hide', height: 'hide' },
            cssArrows: false,
          });
        }
    };

    const menuInvert = () => {
        $('#primary-menu .mega-menu-content, #primary-menu ul ul').each(function (index, element) {
          var $menuChildElement = $(element),
            menuChildOffset = $menuChildElement.offset(),
            menuChildWidth = $menuChildElement.width(),
            menuChildLeft = menuChildOffset.left;

          if (windowWidth - (menuChildWidth + menuChildLeft) < 0) {
            $menuChildElement.addClass('menu-pos-invert');
          }
        });
    };

    const menufunctions = () => {
        $('#primary-menu ul li:has(ul)').addClass('sub-menu');
        $('.top-links > ul').addClass('clearfix');
        if( $('#primary-menu').hasClass('on-click') || ($('body').hasClass('device-md') || $('body').hasClass('device-sm') || $('body').hasClass('device-xs'))) {
          $('#primary-menu li:has(ul) > a').on('click touchend', function (e) {
            $(this).parents('.sub-menu').siblings().find('ul,.mega-menu-content').removeClass('d-block');
            $(this).parent('li').children('ul,.mega-menu-content').toggleClass('d-block');
            e.preventDefault();
          });
        }
    };

    const fullWidthMenu = () => {
      
        if (!$('body').hasClass('stretched')) {
          if ($('#header').find('.container-fullwidth').length > 0) {
            $('.mega-menu .mega-menu-content').css({ width: $('#wrapper').width() - 120 });
          }
          if ($('#header').hasClass('full-header')) {
            $('.mega-menu .mega-menu-content').css({ width: $('#wrapper').width() - 80 });
          }
        }
    };

    const overlayMenu = () => {
        if ($('body').hasClass('overlay-menu')) {
          var overlayMenuItem = $('#primary-menu').children('ul').children('li'),
            overlayMenuItemHeight = overlayMenuItem.outerHeight(),
            overlayMenuItemTHeight = overlayMenuItem.length * overlayMenuItemHeight,
            firstItemOffset = (windowWidth.height() - overlayMenuItemTHeight) / 2;

          $('#primary-menu').children('ul').children('li:first-child').css({ 'margin-top': firstItemOffset + 'px' });
        }
    };

    useEffect(() => {
        const initHeader = () => {
            superfish();
            menufunctions();
            fullWidthMenu();
            overlayMenu();
        };
        
        const timeoutId = setTimeout(() => {
            initHeader();
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, []);
    var megaMenuHTML = "";

    if(loading)
    {
        return '';
    }
    else
    {
        megaMenuHTML = 
        villaList.map((item) => (
                  <li key={item.id} className="sub-menu">
                    <Link to={`/villa-type/${item.slug}/`} onClick={handleClick}>
                      <div>{item.name}</div>
                    </Link>
                   {item.villas && (
                    <ul>
                      {item.villas.map((villa) => (
                        <li key={villa.id} className="sub-menu">
                          <Link to={`/villa/${villa.slug}/`} onClick={handleClick}>
                            <div>{villa.title}</div>
                          </Link>
                          <div className="mega-menu-image-container">
                            <div
                              className="mega-menu-image"
                              style={{
                                backgroundImage: `url(${item.background_image})`,
                              }}
                            ></div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    )}
                  </li>
                ));
    }

    return (
        <div className="mega-menu-content style-2 clearfix"  style={{
                                display: 'none',
                              }}>
            <ul className="mega-menu-column">
                {megaMenuHTML}
            </ul>
        </div>
    );
}

export default MegaMenu;
