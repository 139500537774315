import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

function BlogsBlock() {

    const [loading, setLoading] = useState(true);
    const [bloglist, setBloglist] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/blog`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setBloglist(res.data.blog)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);


    var blogHTML = "";
    if(loading)
    {
        return '';
    }
    else
    {
        blogHTML = 
        bloglist.map( (item) => {
            return (
             <div key={item.id} className="col-md-6">
                <div className="vlogs-box">
                    <div className="glog-title">
                        <h2>
                            <Link to={`/blog/${item.slug}/`}>{item.title}</Link>
                        </h2>
                    </div>
                    <figure className="flog-img">
                        {item.featured_image ? (
                         <Link to={`/blog/${item.slug}/`}>
                            <img src={item.featured_image} className="img-fluid" alt={item.title} loading="lazy" />
                         </Link>
                        ) : (
                        <Link to={`/blog/${item.slug}/`}>
                            <img src={item.featured_image} className="invisible img-fluid" alt={item.title} loading="lazy" />
                         </Link>
                        )}
                        
                        <div className="vlog-meta">
                            <ul>
                                <li><i className="bi bi-person"></i>{item.author}</li>
                                <li><i className="bi bi-calendar"></i>{item.publish_date}</li>
                            </ul>
                        </div>
                    </figure>
                    <div className="vlog-content">
                        <p>{parse( item.description )}</p>
                    </div>
                </div>
            </div>
            )
        });
    }

    return  (
  		<section className="vlogs-block">
            <div className="container">
                <div className="row">
                    {blogHTML}
                </div>
                <div className="know-more text-center">
                	<Link to="/blogs/">More Blogs</Link>
                </div>
            </div>
        </section>
    )
}

export default BlogsBlock;
