import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

function Excursions() {
    const [page, setPage]           = useState([]);
    return  (
      <>
      <section 
        className="Banner IBanner position-relative"
        >
        <div className="PageHead">
          <h1 className="text-uppercase text-white"> Excursions </h1>
          <span className="text-white">{page.subtitle}</span>
          <ol className="breadcrumb d-block">
            <li className="breadcrumb-item"> 
              <Link to="/">Home</Link> 
            </li>
            <li className="breadcrumb-item active"> 
              <Link to="/excursions/"> Excursions </Link> 
            </li>
          </ol>
        </div>
      </section>

      <section className="GridSection">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mt-4">
                    <div className="grid-box shadow bg-white rounded">
                      <div className="Holder position-relative bg-black">
                          <Link to="/transport-excursions/" className="HolderOverlay d-block position-relative">
                            <img src="https://marrakechconcierge.co.uk/marra/uploads/1710852922marrakech_concierge-3.jpg" alt="Transport & Excursions" className="img-fluid" loading="lazy" />
                          </Link>
                      </div>
                      <div className="grid-content">
                        <Link to="/transport-excursions/">
                          <h3 className="fw-bold m-0 mt-3">Transport & Excursions</h3>
                        </Link>
                      </div>
                    </div>
                </div>
                
                <div className="col-md-4 mt-4">
                    <div className="grid-box shadow bg-white rounded">
                      <div className="Holder position-relative bg-black">
                          <Link to="/hot-air-balloon/" className="HolderOverlay d-block position-relative">
                            <img src="https://marrakechconcierge.co.uk/marra/uploads/1703581750Panorama-2.jpg" alt="Hot Air Balloon" className="img-fluid" loading="lazy" />
                          </Link>
                      </div>
                      <div className="grid-content">
                        <Link to="/hot-air-balloon/">
                          <h3 className="fw-bold m-0 mt-3">Hot Air Balloon</h3>
                        </Link>
                      </div>
                    </div>
                </div>

                <div className="col-md-4 mt-4">
                    <div className="grid-box shadow bg-white rounded">
                      <div className="Holder position-relative bg-black">
                          <Link to="/quad-bike/" className="HolderOverlay d-block position-relative">
                            <img src="https://marrakechconcierge.co.uk/marra/uploads/1703585817Quad-bike-1024x538.jpg" alt="Quad Bike" className="img-fluid" loading="lazy" />
                          </Link>
                      </div>
                      <div className="grid-content">
                        <Link to="/quad-bike/">
                          <h3 className="fw-bold m-0 mt-3">Quad Bike</h3>
                        </Link>
                      </div>
                    </div>
                </div>
            </div>           
        </div>
      </section>
    </>
    )
}

export default Excursions;
