import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function VillaDetailTabs(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [villa, setVilla] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const villa_slug = exampleProp.match.params.slug;
        axios.get(`/api/villa/${villa_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setVilla(res.data.villa);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    return (
     <div className="DetailTab mt-5">
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {villa.content && (
            <button 
              className="nav-link active" 
              id="nav-description-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-description" 
              type="button" 
              role="tab" 
              aria-controls="nav-description" 
              aria-selected="true">
                <i className="fa-solid fa-circle-info"></i> 
                <span className="m-2">Description</span>
            </button>
          )}

          {villa.location && (
            <button 
              className="nav-link" id="nav-location-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-location" 
              type="button" 
              role="tab" 
              aria-controls="nav-location" 
              aria-selected="false">
                <i className="fa-solid fa-map"></i>
                <span className="m-2">Location</span>
            </button>
          )}

          {villa.availability && (
            <button 
              className="nav-link" id="nav-availability-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-availability" 
              type="button" 
              role="tab" 
              aria-controls="nav-availability" 
              aria-selected="false">
                <i className="fa-solid fa-align-justify"></i>
                <span className="m-2">Availability</span>
            </button>
          )}

          {villa.facilities && (
            <button 
              className="nav-link" 
              id="nav-facilities-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-facilities" 
              type="button" 
              role="tab" 
              aria-controls="nav-facilities" 
              aria-selected="false">
                <i className="fa-solid fa-rocket"></i> 
                <span className="m-2">Facilities</span>
            </button>
          )}

          {/* {villa.what_nearby && (
            <button 
              className="nav-link" 
              id="nav-things-to-do-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#nav-things-to-do" 
              type="button" 
              role="tab" 
              aria-controls="nav-things-to-do" 
              aria-selected="false">
                <i className="fa-solid fa-puzzle-piece"></i> 
                <span className="m-2">Things To Do</span>
            </button>
          )} */}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        {villa.content && (
          <div 
            className="tab-pane fade show active" 
            id="nav-description" 
            role="tabpanel" 
            aria-labelledby="nav-description-tab" 
            tabIndex="0">
            <div className="Info">
              {parse( villa.content )}
            </div>
          </div>
        )}
        
        {villa.location && (
          <div 
            className="tab-pane fade" 
            id="nav-location" 
            role="tabpanel" 
            aria-labelledby="nav-location-tab" 
            tabIndex="0">
              <div className="Location">
                {parse( villa.location )}
                {villa.map && (
                  <div className="mt-5">
                    {parse(villa.map)}
                  </div>
                 )}
              </div>
          </div>
        )}

        {villa.availability && (
          <div 
            className="tab-pane fade" 
            id="nav-availability" 
            role="tabpanel" 
            aria-labelledby="nav-availability-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( villa.availability )}
              </div>
          </div>
        )}

        {villa.facilities && (
          <div 
            className="tab-pane fade" 
            id="nav-facilities" 
            role="tabpanel" 
            aria-labelledby="nav-facilities-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( villa.facilities )}
              </div>
          </div>
        )}
        {/*
        {villa.what_nearby && (
          <div 
            className="tab-pane fade" 
            id="nav-things-to-do" 
            role="tabpanel" 
            aria-labelledby="nav-things-to-do-tab" 
            tabIndex="0">
              <div className="Info">
                {parse( villa.what_nearby )}
              </div>
          </div>
        )}
        */}
      </div>
    </div>
    )
}

export default VillaDetailTabs;