import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function ItineraryBlock() {

    const [itinerary, setItinerary] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/itinerary`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setItinerary(res.data.itinerary);
                }
            }
        });

        return () => {
            isMounted = false;
        };

    }, []);
    const handleMenuClick = (el) => {
        window.location = el.currentTarget.getAttribute('href');
    };
    return  (
        <section className="itinerary-block">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="itinerary-img">
                          {itinerary.featured_image && (
                            <img src={itinerary.featured_image} className="img-fluid" alt={itinerary.title} loading="lazy" />
                          )}
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="itinerary-content">
                            <div className="itinerary-title">
                                <h2 translate="no">Tailor Made</h2>
                                 {itinerary.title && (
                                    <h4>{itinerary.title}</h4>
                                 )}
                            </div>
                            <div className="inner-content">
                                {itinerary.short_description && (
                                    <p>{itinerary.short_description}</p>
                                )}
                                {itinerary.link && (
                                <div className="know-more">
                                    <Link 
                                        to={itinerary.link} 
                                        onClick={handleMenuClick}>
                                            Create Now
                                    </Link>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ItineraryBlock;
