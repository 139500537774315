import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function PageDetailBreadCrumb(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [page, setPage] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const page_slug = exampleProp.match.params.page_slug;
        axios.get(`/api/page/detail/${page_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.page_slug, history]);
   
    return (
     <section 
        className="Banner IBanner position-relative"
        style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
       >
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> {page.title} </h1>
        <span className="text-white">{page.subtitle}</span>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/page/${page.slug}/`}> {page.title} </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default PageDetailBreadCrumb;