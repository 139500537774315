import { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Link,useHistory } from 'react-router-dom';
import axios from 'axios';

export default function CheckoutForm({ clientSecret }) {
    const stripe = useStripe();
    const elements = useElements();
    const [cartItem, setCartItem] = useState('');
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const history = useHistory();

    const [checkoutInput, setCheckoutInput] = useState({
        first_name: '',
        last_name: '',
        company_name:'',
        country: 'United Kingdom',
        house_number_street_name: '',
        apartment_suite_unit: '',
        town_city: '',
        county: '',
        postcode: '',
        phone:'',
        email_address: '',
        order_notes: '',
        cart_items:'',
    });
    const [errorlist, setError] = useState([]);
    const [successMessage, setSuccessMessage] = useState();
    const handleInput = (e) => {
      e.persist();
      setCheckoutInput({...checkoutInput, [e.target.name]:e.target.value });
    }

    useEffect(() => {
        let isMounted = true;

        // Fetch cart item from localStorage
        const cartItemFromStorage = localStorage.getItem('cartItem');

        // If cart item exists in localStorage, parse it into an object
        if (cartItemFromStorage) {
            try {
                const parsedCartItem = JSON.parse(cartItemFromStorage);
                setCartItem(parsedCartItem);
            } catch (error) {
                console.error('Error parsing cart item:', error);
            }
        }

        return () => {
            isMounted = false
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);
        const cardElement = elements.getElement(CardElement);
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });
        const formData = new FormData();
        formData.append('first_name', checkoutInput.first_name);
        formData.append('last_name', checkoutInput.last_name);
        formData.append('country', checkoutInput.country);
        formData.append('house_number_street_name', checkoutInput.house_number_street_name);
        formData.append('town_city', checkoutInput.town_city);
        formData.append('postcode', checkoutInput.postcode);
        formData.append('phone', checkoutInput.phone);
        formData.append('email_address', checkoutInput.email_address);
        axios.post(`/api/payment/success/validation`, formData).then(resp=>{

          if(resp.data.status === 200)
          {            
            try {
              if (error) {
                  setMessage(error.message);
              } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                  formData.append('county', checkoutInput.county);
                  formData.append('company_name', checkoutInput.company_name);
                  formData.append('apartment_suite_unit', checkoutInput.apartment_suite_unit);
                  formData.append('order_notes', checkoutInput.order_notes);
                  formData.append('ci_product_name', cartItem.productName);
                  formData.append('ci_product_slug', cartItem.productSlug);
                  formData.append('ci_product_image', cartItem.productImage);
                  formData.append('ci_adult_count', cartItem.adultCount);
                  formData.append('ci_adult_price', cartItem.productAdultPrice);
                  formData.append('ci_child_count', cartItem.childCount);
                  formData.append('ci_child_price', cartItem.productChildPrice);

                  formData.append('ci_lead_passenger', cartItem.leadPassengerName);
                  formData.append('ci_mobile', cartItem.mobileNumber);
                  formData.append('ci_airline', cartItem.airline);
                  formData.append('ci_flight_no', cartItem.flightNo);
                  formData.append('ci_arrival_date', cartItem.departureData);
                  formData.append('ci_arrival_time', cartItem.departureTime);
                  formData.append('ci_total_price', cartItem.totalPrice);

                  formData.append('pi_id',paymentIntent.id);
                  formData.append('pi_created',paymentIntent.created);
                  formData.append('pi_payment_method_types',paymentIntent.payment_method_types);
                  formData.append('pi_status',paymentIntent.status);
                 // formData.append('pi_payment_method'.paymentIntent.payment_method);
                  axios.post(`/api/payment/success`, formData).then(res=>{
                      if(res.data.status === 200)
                      {
                        localStorage.removeItem('cartItem');
                        history.push('/payment/success');
                      }
                  });
              }
            } catch (error) {
              console.error('Error confirming payment:', error);
              setMessage('An error occurred while processing your payment. Please try again later.');
            }
          }
          else if(resp.data.status === 422)
          {
            setError(resp.data.errors);
          }
          setIsProcessing(false);
       });
    };

    return (
      <div>
          {cartItem ? (
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mt-5">
              <h2 className="fw-bold">Billing Details </h2>
              <div className="FormHolder">
                
                  <div className="row">
                    <div className="col-md-6">
                      <div className="FormWrap mt-3">
                        <label className="form-label"><span>*</span>First NAME</label>
                        <input 
                        type="text" 
                        name="first_name" 
                        onChange={handleInput} 
                        value={checkoutInput.first_name}  
                        className="form-control" 
                        placeholder="First Name*"/>
                      </div>
                      <small className="text-danger">{errorlist.first_name}</small>
                    </div>
                    <div className="col-md-6">
                      <div className="FormWrap mt-3">
                        <label className="form-label"><span>*</span>Last NAME</label>
                        <input 
                        type="text" 
                        name="last_name" 
                        onChange={handleInput} 
                        value={checkoutInput.last_name}  
                        className="form-control" 
                        placeholder="Last Name*"/>
                      </div>
                      <small className="text-danger">{errorlist.last_name}</small>
                    </div>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label">COMPANY NAME (OPTIONAL)</label>
                    <input 
                    type="text" 
                    name="company_name" 
                    onChange={handleInput} 
                    value={checkoutInput.company_name}  
                    className="form-control" 
                    placeholder="COMPANY NAME (OPTIONAL)"/>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>COUNTRY/REGION</label>
                    <select 
                    name="country" 
                    onChange={handleInput} 
                    value={checkoutInput.country} 
                    className="form-select">
                      <option value="">Select a country</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cabo Verde">Cabo Verde</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
                      <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Eswatini (fmr. &quot;Swaziland&quot;)">Eswatini (fmr. "Swaziland")</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Greece">Greece</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-Bissau">Guinea-Bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Holy See">Holy See</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia">Micronesia</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="North Korea">North Korea</option>
                      <option value="North Macedonia">North Macedonia</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestine State">Palestine State</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Korea">South Korea</option>
                      <option value="South Sudan">South Sudan</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-Leste">Timor-Leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States of America">United States of America</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <small className="text-danger">{errorlist.country}</small>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>STREET ADDRESS</label>
                    <input 
                      type="text" 
                      name="house_number_street_name" 
                      onChange={handleInput} 
                      value={checkoutInput.house_number_street_name}  
                      className="form-control" 
                      placeholder="House number and street name"
                    />
                    <small className="text-danger">{errorlist.house_number_street_name}</small>
                    <input 
                      type="text" 
                      name="apartment_suite_unit" 
                      onChange={handleInput} 
                      value={checkoutInput.apartment_suite_unit}  
                      className="form-control mt-3" 
                      placeholder="Apartment, suite, unit, etc. (optional)"
                    />
                    
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>TOWN/CITY</label>
                    <input  
                      type="text" 
                      name="town_city" 
                      onChange={handleInput} 
                      value={checkoutInput.town_city}  
                      className="form-control" 
                      placeholder="TOWN/CITY"/>
                      <small className="text-danger">{errorlist.town_city}</small>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label">COUNTY (OPTIONAL) </label>
                    <input  
                      type="text" 
                      name="county" 
                      onChange={handleInput} 
                      value={checkoutInput.county}  
                      className="form-control" 
                      placeholder="COUNTY (OPTIONAL)"/>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>POSTCODE</label>
                    <input 
                    type="text" 
                    name="postcode" 
                    onChange={handleInput} 
                    value={checkoutInput.postcode}  
                    className="form-control" 
                    placeholder="POSTCODE"/>
                    <small className="text-danger">{errorlist.postcode}</small>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>PHONE</label>
                    <input
                    type="text" 
                    name="phone" 
                    onChange={handleInput} 
                    value={checkoutInput.phone}  
                    className="form-control" 
                    placeholder="PHONE"/>
                    <small className="text-danger">{errorlist.phone}</small>
                  </div>
                  <div className="FormWrap mt-3">
                    <label className="form-label"><span>*</span>EMAIL ADDRESS</label>
                    <input
                    type="email" 
                    name="email_address" 
                    onChange={handleInput} 
                    value={checkoutInput.email_address}  
                    className="form-control" 
                    placeholder="EMAIL ADDRESS" />
                    <small className="text-danger">{errorlist.email_address}</small>
                  </div>
                  <h5 className="mt-3 fw-bold">Additional information</h5>
                  <label className="form-label">ORDER NOTES (OPTIONAL)</label>
                  <div className="FormWrap mt-3">
                    <textarea 
                    name="order_notes" 
                    onChange={handleInput} 
                    value={checkoutInput.order_notes} 
                    className="form-control" 
                    placeholder="Leave a comment here"
                    rows="3">
                    </textarea>
                  </div>
                
              </div>
            </div>
            <div className="col-md-6 mt-5">
              <div className="OrderDetails">
                <h2 className="fw-bold">Your Order</h2>
                <div className="OrderTable mt-5">
                  <table className="table">
                    <thead className="table-light">
                      <tr>
                        <th className="ProductName">Product</th>
                        <th className="SubTotal">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ProductName">
                            <div className="ProductInfo">
                            <h4>
                              <Link to={`/product/${cartItem.productSlug}/`} >{cartItem.productName}</Link>
                            </h4>
                            <ul className="ProductMeta">

                              <li>Adult (Ages 12 and above):<span> {cartItem.adultCount} * £{cartItem.productAdultPrice}</span> </li>
                              <li> Child (Ages 2-12):<span> {cartItem.childCount} * £{cartItem.productChildPrice} </span></li>
                              <li> Lead Passenger Full Name:<span> {cartItem.leadPassengerName} </span></li>
                              <li> Mobile Number (Incl. Country Code) :<span> {cartItem.mobileNumber} </span></li>
                              <li> Airline:<span> {cartItem.airline} </span></li>
                              <li> Flight No.:<span> {cartItem.flightNo}</span> </li>
                              <li> Arrival Date:<span> {cartItem.departureData} </span></li>
                              <li>Arrival Time:<span> {cartItem.departureTime} </span></li>
                            </ul>
                          </div>
                        </td>
                        {cartItem.totalPrice && (
                          <td className="SubTotal">£{cartItem.totalPrice}</td>
                        )}
                      </tr>
                      {cartItem.totalPrice && (
                        <tr className="SubTotal">
                          <td>Subtotal</td>
                          <td>£{cartItem.totalPrice}</td>
                        </tr>
                      )}
                      {cartItem.totalPrice && (
                      <tr className="Total">
                        <td>Total</td>
                        <td>£{cartItem.totalPrice}</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="CardInfo bg-light p-4">
                  <h5 className="fw-bold">Credit Card (Stripe)</h5>
                  <div className="CardBox mt-3">
                    <p>Pay with your credit card via Stripe.</p>
                    <label className="mt-2">CREDIT OR DEBIT CARD</label>
                    <CardElement className="form-control" />
                  </div>
                  <div className="Note mt-4">
                    <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy. </p>
                  </div>
                  <button className="btn BtnOrange" disabled={isProcessing || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isProcessing ? "Processing ... " : "Place Order"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      ): (
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <p>Your basket is currently empty.</p>
            <p><Link to="/fast-track/" className="btn BtnOrange">Return To Shop</Link></p>
          </div>
        </div>
      )}
      </div>  
    );
}
