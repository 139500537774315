import React from 'react';
import TopHeader from './TopHeader';
import HeaderLogo from './HeaderLogo';
import PrimaryMenu from './PrimaryMenu';

const Header = () => {
	return (
		<header className="header">
            <TopHeader />
            <div id="header" className="sticky-style-2 full-header clearfix">
                <div id="header-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <HeaderLogo />
                            <PrimaryMenu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
	);
}

export default Header;