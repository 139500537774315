import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function VillaDetailBanner(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [galleries, setGalleries] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const villa_slug = exampleProp.match.params.slug;
        axios.get(`/api/villa/${villa_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setGalleries(res.data.galleries);
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    var bannerHTML = "";
    if(loading)
    {
        return <h4>Loading...</h4>
    }
    else
    {
        bannerHTML = 
        galleries.map( (item) => {
            return (
           <div key={item.id} className={`carousel-item ${item.id === 1 ? 'active' : ''}`}> 
             <img src={item.url} className="d-block w-100" alt="Detail" />
           </div>
            )
        });
    }
    return (
      <div className="Banner mt-5">
       <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner">
          {bannerHTML}
        </div>
        <div className="CarouselThumb">
          <ul>
          { galleries.map((item) => ( 
            <li 
              key={item.id}
              type="button" 
              data-bs-target="#carouselExampleIndicators" 
              data-bs-slide-to={item.id - 1} 
              className={`${item.id === 1 ? 'active' : ''}`}
              aria-current="true" aria-label={`Slide ${item.id}`}>
              <img src={item.url} alt={`Slider ${item.id}`} />
            </li>
           ))}
          </ul>
        </div>
      </div>
    </div>
    )
}

export default VillaDetailBanner;

     