import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios';

function FastTrack() {
    const [page, setPage]           = useState([]);
    const [productData, setProduct] = useState([]);
    useEffect(() => {
      let isMounted = true;
      axios.get(`/api/page/product`).then(res=>{
          if(isMounted)
          {
            if(res.status === 200)
            {
                setPage(res.data.page);
            }
          }
      });

      axios.get(`/api/productList`).then(res=>{
          if(isMounted)
          {
            if(res.status === 200)
            {
                setProduct(res.data.product);
            }
          }
      });

      return () => {
          isMounted = false
      };

    }, []);

    return  (
      <>
      <section 
        className="Banner IBanner position-relative"
        style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
        >
        <div className="PageHead">
          <h1 className="text-uppercase text-white"> {page.title} </h1>
          <span className="text-white">{page.subtitle}</span>
          <ol className="breadcrumb d-block">
            <li className="breadcrumb-item"> 
              <Link to="/">Home</Link> 
            </li>
            <li className="breadcrumb-item active"> 
              <Link to="/fast-track/"> {page.title} </Link> 
            </li>
          </ol>
        </div>
      </section>


      <section className="ListSection">
        <div className="container">
        {Object.entries(productData).map(([category, products]) => (
         <div key={category}>
          <div className="row">
            <div className="col-md-12 mt-5 text-center">
              <h2 className="SubTitle fw-bold">{category}</h2>
              <div className="title-bottom-border-v1"></div>
            </div>
          </div>
          {products.map(product => (
            <div key={product.id} className="row">
              <div className="col-md-4 mt-4">
                <figure>
                  <img src={product.featured_image} alt={product.title} className="img-fluid w-100" />
                </figure>
              </div>
              <div className="col-md-8 mt-4">
                <div className="Holder">
                  <h3 className="fw-bold">{product.title}</h3>
                  <span className="Cost fw-bold fst-italic">Price: £{product.adult_price} </span>
                  <div className="Summary py-3">
                    {product.content && (
                      <>{parse(product.content)}</>
                    )}
                  </div>
                  <Link
                    to={`/product/${product.slug}/`} 
                    className="BtnOrange fst-italic text-uppercase px-4 py-2">
                    Book Now
                  </Link> 
                  </div>
              </div>
            </div>
          ))}
        </div>
         ))}
          {page.content && (
            <div className="PageArticle mt-5">{parse(page.content)}</div>
          )}
        </div>
      </section>
    </>
    )
}

export default FastTrack;
