import React, {useState} from 'react';
import {Route, Switch,useHistory,useLocation} from 'react-router-dom';
import '../assets/css/main.css';
import '../assets/css/owl.carousel.min.css';

import Header from './Header';
import Footer from './Footer';
import mcLogoImg from '../assets/img/MC-Logo.jpg';
import introImg from '../assets/img/intro.jpg';
import whatsappImg from '../assets/img/whatsapp.png';
import publicRoutesList from '../routes/PublicRouteList';

const MasterLayout = () => {

    const location  = useLocation();
    const history   = useHistory();
    const [isVisible, setIsVisible] = useState(true);
    const moduleParam = new URLSearchParams(location.search).get('module');
    
    if(window.location.pathname.startsWith('/night-life/') || window.location.pathname.startsWith('/night-life')) {
      history.push('/parties/night-life/');
    }

    if(window.location.pathname.startsWith('/day-parties/') || window.location.pathname.startsWith('/day-parties')) {
      history.push('/parties/day-parties/');
    }

    if( moduleParam ){
        if( moduleParam === 'night-life' || moduleParam === 'day-parties' ){
            history.push('/parties/'+moduleParam);
        } else {
            history.push(moduleParam);
        }
    }
    const toggleWhatsApp = () => {
       setIsVisible(!isVisible);
    };

	return (
		<div id="wrapper">
			<Header />
	            <Switch>
                    {publicRoutesList.map((routedata, idx) => {
                        return (
                            routedata.component && (
                                <Route 
                                    key={idx}
                                    path={routedata.path}
                                    exact={routedata.exact}
                                    name={routedata.name}
                                    render={(props) => (
                                        <routedata.component {...props} />
                                    )}
                                />
                            )
                        )
                    })}
                </Switch>
			<Footer />
            <div className="marra-whatsapp-container marra-whatsapp-round-toggle-on-desktop marra-whatsapp-round-toggle-on-mobile" data-delay-time="" data-inactive-time="" data-scroll-length="">
              <div className="marra-whatsapp-box marra-whatsapp-js-ready" style={{
                  visibility: isVisible ? 'hidden' : 'visible',
                  opacity: isVisible ? 0 : 1
                }}>
                    <div className="marra-whatsapp-description">
                      <p>Hi there! Click one of our representatives below and we will get back to you as soon as possible.</p>
                    </div>
                    <span className="marra-whatsapp-close" onClick={toggleWhatsApp}></span>
                    <div className="marra-whatsapp-people" style={{ maxHeight: "724px" }}>
                      <a href="https://wa.me/447956559298?text=Hi Marrakech Concierge, I would like to get more information about your services." data-number="447956559298" className="marra-whatsapp-account marra-whatsapp-clearfix" data-auto-text="Hi Marrakech Concierge, I would like to get more information about your services." data-ga-label="Sirhan" target="_blank">
                        <div className="marra-whatsapp-face">
                          <img src={mcLogoImg} onError={(e)=> { e.target.style.display = 'none'; }} alt="Representative" />
                        </div>
                        <div className="marra-whatsapp-info">
                          <span className="marra-whatsapp-title">Enquiries for Restaurants, Fast Track, Activities and Customer Service</span>
                          <span className="marra-whatsapp-name">SQ</span>
                        </div>
                      </a>
                      <a href="https://wa.me/447915068592?text=Hi Marrakech Concierge, I would like to get more information about your services." data-number="447915068592" className="marra-whatsapp-account marra-whatsapp-clearfix" data-auto-text="Hi Marrakech Concierge, I would like to get more information about your services." data-ga-label="Dhiren" target="_blank">
                        <div className="marra-whatsapp-face">
                          <img src={introImg} onError={(e)=> { e.target.style.display = 'none'; }} alt="Representative" />
                        </div>
                        <div className="marra-whatsapp-info">
                          <span className="marra-whatsapp-title">Enquiries for Villas, Clubs, Restaurants and Transport</span>
                          <span className="marra-whatsapp-name">DS</span>
                        </div>
                      </a>
                    </div>
              </div>
              <div className="marra-whatsapp-toggle">
                <a href="javascript:void(0)" onClick={toggleWhatsApp}>
                   <img src={whatsappImg} onError={(e)=> { e.target.style.display = 'none'; }} alt="Representative" />
                </a>
                <span className="marra-whatsapp-text">Chat with us on WhatsApp</span>
              </div>
              <div className="marra-whatsapp-mobile-close">
                <span onClick={toggleWhatsApp}>Close and go back to page</span>
              </div>
            </div>
		</div>
	);
}

export default MasterLayout;