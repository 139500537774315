import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import PublicRoute from './PublicRoute';
import axios from 'axios';

// Production
axios.defaults.baseURL = "https://marrakechconcierge.co.uk/marra";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

function App() {
  return (
      <div className="App">
          <Router>
            <Switch>
              <PublicRoute path="/" name="Home" />
            </Switch>
          </Router>
      </div>
  );
}

export default App;
