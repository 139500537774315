import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link,useHistory} from 'react-router-dom';
import PartyPageContent from './PartyPageContent';
import locationImage from '../../assets/img/icons/location.svg';

function PartyContentPagination(props) {

    const { exampleProp }                 = props;
    const history                         = useHistory();
    const [parties, setParties]           = useState([]);
    const [pageNumber, setPageNumber]     = useState(null);
    const [currentPage, setcurrentPage]   = useState(null);

    useEffect(() => {
        let isMounted = true;
        const party_type_slug = exampleProp.match.params.party_type;
        axios.get(`/api/party_type/${party_type_slug}?page=${pageNumber}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setParties(res.data.party);
                    setPageNumber(res.data.page_number)
                    setcurrentPage(res.data.current_page)
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [exampleProp.match.params.party_type, history]);

    const pages = Array.from({ length: pageNumber }, (_, index) => index + 1);
    return  (
    <section className="GridSection">
      <div className="container">
        <div className="row">
         { parties.map((item) => ( 
          <div key={item.id} className="col-md-4 mt-4">
            <div className="grid-box shadow bg-white rounded">
              <div className="Holder position-relative bg-black"> 
                <Link to={`/party/${item.slug}/`} className="HolderOverlay d-block position-relative"> 
                  {item.featured_image && (
                    <img src={item.featured_image} alt={item.title} className="img-fluid" loading="lazy"/> 
                  )}
                </Link> 
              </div>
              <div className="grid-content">
                <Link to={`/party/${item.slug}/`}>
                  <h3 className="fw-bold m-0 mt-3">{item.title}</h3>
                </Link>
                {item.content && (
                  <p>{item.content} <Link to={`/party/${item.slug}/`} className="btnLogoColor">more</Link></p>
                )}
              </div>
              <div className="divider">
                <hr />
              </div>

              <div className="box-footer">
                <ul>
                  {item.location && (
                    <li>
                      <img src={locationImage} /> <span>{item.location}</span>
                    </li>
                   )}
                </ul>
                
                <Link to={`/party/${item.slug}/`} className="BtnOrange mt-2 d-inline-block">
                  Enquire Now
                </Link>
              
              </div>
            </div>
          </div>
        ))}
        </div>
        <div className="PaginationNav mt-5">
        {pageNumber > 1 && (
          <nav>
            <ul className="pagination">
              {pages.map((page) => (
                <li key={page} className={`page-item ${currentPage === page ? 'active' : '' }`} aria-current={page === 1 ? 'page' : undefined}>
                  <Link to={`/parties/${exampleProp.match.params.party_type}/page/${page}/`} className="page-link">
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
        </div>
        <PartyPageContent dataProp={exampleProp} />
      </div>
    </section>
  )
}

export default PartyContentPagination;
