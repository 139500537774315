import React, {useState, useEffect} from 'react';
import axios from 'axios';
import instagramBg from '../../assets/img/Mareakech-svg-1-03.svg';
import instagramShape from '../../assets/img/Mareakech-svg-1-04.svg';

function Instagram() {

    const [loading, setLoading] = useState(true);
    const [instagramlist, setInstagramlist] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/instagram`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setInstagramlist(res.data.instagram)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);


    var instagramHTML = "";
    if(loading)
    {
        return '';
    }
    else
    {
        instagramHTML = 
        instagramlist.map( (item) => {
            return (
            <div key={item.id} className="col-6 col-md-3">
                <div className="intagram-box">
                    <a href={item.link}>
                        <img src={item.featured_image} className="img-fluid" alt="images" loading="lazy"/>
                    </a>
                </div>
            </div>
            )
        });
    }
    return  (
     <>
        { instagramlist.length > 0 && (
           
            <section className="instagram-block">
                <div className="container">
                    <div className="instagram-bg" 
                    style={{ backgroundImage: `url(${instagramBg})` }}></div>
                    <div className="instagram-shape" 
                    style={{ backgroundImage: `url(${instagramShape})` }}
                    ></div>
                    <div className="intagram-title">
                        <h2>Reels</h2>
                        <h4>Instagram</h4>
                    </div>
                    <div className="row">
                        {instagramHTML}
                    </div>
                </div>
            </section>
            
        ) }
    </>
    )
}

export default Instagram;
