import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PartyDetailBreadCrumb from './PartyDetails/PartyDetailBreadCrumb';
import PartyDetailBanner from './PartyDetails/PartyDetailBanner';
import PartyDetailTabs from './PartyDetails/PartyDetailTabs';
import PartyDetailRelated from './PartyDetails/PartyDetailRelated';
import PartyDetailReserveForm from './PartyDetails/PartyDetailReserveForm';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

function PartyDetail(props)
{
    const history = useHistory();
    const [party, setParty] = useState([]);

    useEffect(() => {
      let isMounted = true;
      const party_slug = props.match.params.slug;
      axios.get(`/api/party/${party_slug}`).then(res=>{
          if(isMounted)
          {
              if(res.data.status === 200)
              {
                setParty(res.data.party);
              }
          }
      });

      return () => {
          isMounted = false
      };
    }, [props.match.params.slug, history]);

    return (
        <div>
          <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`Party - ${party.title}`}</title>
            <meta name="description" content={party.meta_keywords} />
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
            <link rel="canonical" href="https://marrakechconcierge.co.uk/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={party.meta_title} />
            <meta property="og:description" content={party.meta_keywords} />
            <meta property="og:url" content={`https://marrakechconcierge.co.uk/party/${party.slug}`} />
            <meta property="og:site_name" content="Marrakech Concierge | Luxury Tailor-Made Holidays in Marrakech" />
            <meta property="og:image" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:secure_url" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={party.meta_title} />
            <meta property="og:image:type" content="image/jpg" />
            <link rel="apple-touch-icon"  href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
        </Helmet>
         	<PartyDetailBreadCrumb exampleProp={props} />
            <section className="DetailSection">
              <div className="container">
                <div className="row">
                  <div className="col-md-8"> 
                    <PartyDetailBanner exampleProp={props} />
                    <PartyDetailTabs exampleProp={props} />
                  </div>
                  <div className="col-md-4 mt-4">
                    {party.sidebar_content && ( 
                      <div className="RightMeta p-4 mt-4">
                        {parse( party.sidebar_content )}
                      </div>
                    )}
                    <PartyDetailReserveForm exampleProp={props} />
                    <div>
                      {party.package_content && (
                        <div className="RightMeta p-4 mt-4">
                           {parse( party.package_content )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
    )
}

export default PartyDetail;