import React from 'react';
import PartyBanner from './PartySection/PartyBanner';
import PartyContentPagination from './PartySection/PartyContentPagination';

function PartyPagination(props) {
    return  (
    <div>
        <PartyBanner exampleProp={props} />
        <PartyContentPagination exampleProp={props} />
    </div>
    )
}

export default PartyPagination;
