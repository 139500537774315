import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import HotAirBallonReserveForm from './HotAirBallonReserveForm';

function PageDetailContent(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [page, setPage] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const page_slug = exampleProp.match.params.page_slug;
        axios.get(`/api/page/detail/${page_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.page_slug, history]);
   
    return (
          <div className="mt-5">
             {page.content && (
                <>
                  {page.slug === 'hot-air-balloon' ? (
                    <div className="row">
                      <div className="col-md-8">
                        {parse(page.content)}
                      </div>
                      <div className="col-md-4">
                        <div className="DetailSection">
                          <HotAirBallonReserveForm />
                        </div>
                      </div>
                    </div>
                  ) : page.slug === 'enquiry-thank-you' ? (
                    <div className="row text-center">
                      <div className="col-md-12">
                        <h2>{page.title}</h2>
                        {parse(page.content)}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12">
                      {parse(page.content)}
                    </div>
                  )}
                </>
              )}
          </div>
    )
}

export default PageDetailContent;