import Slider from "react-slick";
import React, {useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-prev slick-arrow" onClick={onClick}><i className="bi bi-chevron-left"></i></div>
  );
}

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-next slick-arrow" onClick={onClick}><i className="bi bi-chevron-right"></i></div>
  );
}

const Service = ({ initialSlide = 0 }) => {

    const [loading, setLoading] = useState(true);
    const [servicelist, setServicelist] = useState([]);
    const [hasSetPosition, setHasSetPosition] = useState(false);
    const slider = useRef();

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/service`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setServicelist(res.data.service)
                    setLoading(false);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    useEffect(() => {
        if (slider.current && !hasSetPosition) {
          slider.current.slickGoTo(initialSlide);
          setHasSetPosition(true);
        }
    }, [initialSlide, hasSetPosition, slider]);
    const settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: true,
        focusOnSelect: true,
        touchMove: true,
        adaptiveHeight: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        initialSlide,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 480,
                settings: "unslick",
            }
        ]
    };
    var serviceHTML = "";
    if(loading)
    {
        return <h4>Loading...</h4>
    }
    else
    {
        serviceHTML = 
        servicelist.map( (item) => {
            return (
            <div key={item.id} className="item">
                <div className="Holder bg-black position-relative"> 
                    <Link to={item.url} className="HolderOverlay d-block position-relative"> 
                        <img src={item.featured_image} alt="thumb" className="img-fluid" loading="lazy"/>
                        <h2 className="fw-bold text-center">{item.custom_text}</h2>
                    </Link> 
                </div>
            </div>
            )
        });
    }


    return  (
        <section className="ServicesSection">
            <div className="container">
                <div className="serviceSlider">
                    <Slider ref={slider} {...settings}>
                        {serviceHTML}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Service;
