import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function FastTrackDetailBreadCrumb(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [product, setFastTrack] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const product_slug = exampleProp.match.params.slug;
        axios.get(`/api/product/${product_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setFastTrack(res.data.product);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    return (
     <section 
      className="Banner IBanner position-relative"
      style={product.background_image ? { backgroundImage: `url(${product.background_image})` } : {}}
     >
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> {product.title} </h1>
        <span className="text-white">{product.subtitle}</span>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/product/${product.slug}/`}> {product.title} </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default FastTrackDetailBreadCrumb;