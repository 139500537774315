import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function BlogDetailContent(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [blog, setBlog] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const blog_slug = exampleProp.match.params.slug;
        axios.get(`/api/blog/${blog_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setBlog(res.data.blog);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    return (
     <section className="BlogPage">
	  <div className="container">
	    <div className="row">
	      <div className="col-md-12">
	        <p className="PostMeta mt-3"> 
	        	{blog.author && (
                   <span className="PostBy"><i className="fa-solid fa-user"></i> {blog.author}</span> 
                )}
                {blog.publish_date && (
                  <span className="PostDate"><i className="fa-regular fa-clock"></i> {blog.publish_date}</span> 
                )}
	        </p>
	        {blog.featured_image && (
	            <div className="BigPic mt-4"> 
	              <img src={blog.featured_image} alt={blog.title} className="img-fluid w-100" /> 
	            </div>
	        )}
	       	{blog.description && (
	            <div className="DetailInfo mt-4">
	              {parse(blog.description)}
	            </div>
	        )}
	      </div>
	    </div>
	  </div>
	</section>
    )
}

export default BlogDetailContent;
