import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import locationImage from '../../assets/img/icons/location.svg';

function VillaContent() {

    const [villaList, setVilla] = useState([]);

    const [villaAllList, setAllVilla] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/villaList`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setVilla(res.data.villa)
                    setAllVilla(res.data.allVilla)
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    const activeTabContent = (e) => {
      document.querySelectorAll('.nav-tabs a.nav-link').forEach( (navEl) => {
        navEl.classList.remove('active');
      });
      e.currentTarget.classList.add('active');
      document.querySelectorAll('#villa-tab-content .tab-pane').forEach( (elm) => {
        elm.classList.remove('active');
        elm.classList.remove('show');
      });
      document.getElementById( e.currentTarget.getAttribute('data-id') ).classList.add('active');
      document.getElementById( e.currentTarget.getAttribute('data-id') ).classList.add('show');
    };

    return  (
    <section className="GridSection">
      <div className="container">
        <div className="row">
          <div className="col-md-12 FilterTab clearfix mt-5">
            <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
               <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    data-id="tab-all"
                    data-toggle="tab"
                    href="#tab-all"
                    role="tab"
                    aria-controls="tab-all"
                    aria-selected="true"
                    onClick={activeTabContent}
                    >All
                  </a>
              </li>
              { villaList.map((item) => ( 
                <li key={item.id} className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-id={`tab-${item.slug}`}
                    data-toggle="tab"
                    href={`#tab-${item.slug}`}
                    role="tab"
                    aria-controls={`tab-${item.slug}`}
                    aria-selected="false"
                    onClick={activeTabContent}
                    > {item.name} </a
                  >
                </li>
                ))}
            </ul>
          </div>
        </div>
      
        <div className="tab-content" id="villa-tab-content">
          <div
            className="tab-pane fade show active"
            id="tab-all"
            role="tabpanel"
            aria-labelledby="tab-all"
          >
             <div className="row">
              { villaAllList.map((item) => ( 
                <div key={item.id} className="col-md-4 mt-4">
                  <div className="grid-box shadow bg-white rounded">
                    <div className="Holder position-relative bg-black"> 
                      <Link to={`/villa/${item.slug}/`} className="HolderOverlay d-block position-relative"> 
                        {item.featured_image && (
                          <img src={item.featured_image} alt={item.title} className="img-fluid" loading="lazy"/> 
                        )}
                      </Link> 
                    </div>
                    <div className="grid-content">
                      <Link to={`/villa/${item.slug}/`}> 
                        <h3 className="fw-bold m-0 mt-3">{item.title}</h3>
                      </Link>
                      {item.content && (
                        <p>{item.content} <Link to={`/villa/${item.slug}/`} className="btnLogoColor">more</Link></p>
                      )}
                    </div>
                    <div className="divider">
                      <hr />
                    </div>

                    <div className="box-footer">
                      <ul>
                        {item.location && (
                          <li>
                            <img src={locationImage} /> <span>{item.location}</span>
                          </li>
                         )}
                      </ul>
                      
                      <Link to={`/villa/${item.slug}/`} className="BtnOrange mt-2 d-inline-block">
                        Enquire Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        { villaList.map((item) => ( 
          <div
            key={item.id}
            className="tab-pane fade"
            id={`tab-${item.slug}`}
            role="tabpanel"
            aria-labelledby={`tab-${item.slug}`}
          >
            {item.villas && (
               <div className="row">
                { item.villas.map((villa) => ( 
                  <div key={villa.slug} className="col-md-4 mt-4">
                    <div className="grid-box shadow bg-white rounded">
                      <div className="Holder position-relative bg-black"> 
                        <Link to={`/villa/${villa.slug}/`} className="HolderOverlay d-block position-relative"> 
                          {villa.featured_image && (
                            <img src={villa.featured_image} alt={villa.title} className="img-fluid" loading="lazy"/> 
                          )}
                        </Link> 
                      </div>
                      <div className="grid-content">
                        <Link to={`/villa/${villa.slug}/`}>
                          <h3 className="fw-bold m-0 mt-3">{villa.title}</h3>
                        </Link>
                        {villa.content && (
                          <p>{villa.content} <Link to={`/villa/${villa.slug}/`} className="btnLogoColor">more</Link></p>
                        )}
                      </div>
                      <div className="divider">
                        <hr />
                      </div>

                      <div className="box-footer">
                        <ul>
                          {villa.location && (
                            <li>
                              <img src={locationImage} /> <span>{villa.location}</span>
                            </li>
                           )}
                        </ul>
                        
                        <Link to={`/villa/${villa.slug}/`} className="BtnOrange mt-2 d-inline-block">
                          Enquire Now
                        </Link>
                      
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        </div>    
    </div>
  </section>
  )
}

export default VillaContent;
