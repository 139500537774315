import React, {useState, useEffect} from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

function CustomItinerarySlider() {

    const [galleries, setGalleries] = useState([]);

    const [page, setPage] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/custom/itinerary/galleries`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setGalleries(res.data.galleries);
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    return  (
        <div>
        <div className="">
        {page.title && (
            <h2>{page.title}</h2>
        )}
        {page.subtitle && (
            <h3>{page.subtitle}</h3>
           )}
        </div>
       	<section className="CustomItineraryBanner">
		      <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                { galleries.map((item) => ( 
                <div key={item.id} className={`carousel-item ${item.active}`}>
                  <img className="d-block w-100" src={item.url} alt="First slide" /> 
                </div>
               ))}
            </div>
          </div>
        </section>
         <div className="heading pb-5">
          {page.content && (
            <h4 className="has-text-align-center">
              {parse( page.content ) }
            </h4>
          )}
          </div>
        </div>
    )
}

export default CustomItinerarySlider;


