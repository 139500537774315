import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link,useHistory} from 'react-router-dom';
import GolfPageContent from './GolfPageContent';
import locationImage from '../../assets/img/icons/location.svg';

function GolfContentPagination(props) {

    const { exampleProp } = props;
    const history = useHistory();
    const [golfList, setGolf] = useState([]);
    const [pageNumber, setPageNumber]     = useState([]);
    const [currentPage, setcurrentPage]   = useState([]);

    useEffect(() => {
      let isMounted = true;

      const pageNumber = exampleProp.match.params.page_number;
      axios.get(`/api/golfList?page=${pageNumber}`).then(res=>{
          if(isMounted)
          {
              if(res.status === 200)
              {
                  setGolf(res.data.golf);
                  setPageNumber(res.data.page_number)
                  setcurrentPage(res.data.current_page)
              }
          }
      });

      return () => {
          isMounted = false
      };

    }, [exampleProp.match.params.page_number, history]);

    const pages = Array.from({ length: pageNumber }, (_, index) => index + 1);

    return  (
      <section className="GridSection">
        <div className="container">
          <div className="row">
           { golfList.map((item) => ( 
            <div key={item.id} className="col-md-4 mt-4">
              <div className="grid-box shadow bg-white rounded">
                <div className="Holder position-relative bg-black"> 
                  <Link to={`/golf/${item.slug}/`} className="HolderOverlay d-block position-relative"> 
                    {item.featured_image && (
                      <img src={item.featured_image} alt={item.title} className="img-fluid" loading="lazy"/> 
                    )}
                  </Link> 
                </div>
                <div className="grid-content">
                  <Link to={`/golf/${item.slug}/`}>
                    <h3 className="fw-bold m-0 mt-3">{item.title}</h3>
                  </Link>
                  {item.content && (
                    <p>{item.content} <Link to={`/golf/${item.slug}/`} className="btnLogoColor">more</Link></p>
                  )}
                </div>
                <div className="divider">
                  <hr />
                </div>

                <div className="box-footer">
                  <ul>
                    {item.location && (
                      <li>
                        <img src={locationImage} /> <span>{item.location}</span>
                      </li>
                    )}
                  </ul> 
                  <Link to={`/golf/${item.slug}/`} className="BtnOrange mt-2 d-inline-block">
                    Enquire Now
                  </Link>
                </div>
              </div>
            </div>
          ))}
          </div>
        
          <div className="PaginationNav mt-5">
            <nav>
              <ul className="pagination">
              {pages.map((page) => (
                <li key={page} className={`page-item ${currentPage === page ? 'active' : '' }`} aria-current={page === 1 ? 'page' : undefined}>
                  <Link to={`/golfs/page/${page}/`} className="page-link">
                    {page}
                  </Link>
                </li>
              ))}
              </ul>
            </nav>
          </div>

          <GolfPageContent />
        </div>
      </section>
  )
}

export default GolfContentPagination;
