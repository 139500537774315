import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function VillaTypeBanner(props) {

    const { exampleProp } = props;
    const history = useHistory();
    const [villaType, setVillaType] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const villa_type_slug = exampleProp.match.params.villa_type;
        axios.get(`/api/villa_type/${villa_type_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setVillaType(res.data.villa_type);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [exampleProp.match.params.villa_type, history]);

    return  (
    <section 
    className="Banner IBanner position-relative"
    style={villaType.background_image ? { backgroundImage: `url(${villaType.background_image})` } : {}}
    >
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> {villaType.name} </h1>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/villa-type/${villaType.slug}/`}> {villaType.name} </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default VillaTypeBanner;
