import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function About() {

    const [about, setAbout] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/about`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setAbout(res.data.about);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);
   
    return  (
        <section className="about-block">
            <div className="container">
                <div className="row">
                     <div className="col-md-5">
                        <div className="about-img">
                          {about.featured_image && (
                            <img src={about.featured_image} className="img-fluid" alt={about.title} loading="lazy" />
                          )}
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="about-content">
                            {about.title && (
                                <h2>{about.title}</h2>
                            )}
                            {about.short_description && (
                                <p>{about.short_description}</p>
                            )}
                            {about.link && (
                                <div className="know-more">
                                    <Link to={about.link}>Know More</Link>
                                </div>
                            )}
                            <div className="se-name">
                                <h3 translate="no">Marrkech Concierge</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
