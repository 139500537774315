import React from 'react';
import {Link} from 'react-router-dom';

const PaymentSuccess = () => {
  return (
    <div>
      <section className="Banner IBanner position-relative">
        <div className="PageHead">
          <h1 className="text-uppercase text-white">Payment Success</h1>
        </div>
      </section>

      <section className="CartPage text-center mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Success</h2> 
              <p>Your Payment has been Success.</p>
              <p><Link to="/fast-track/" className="btn BtnOrange">Return To Shop</Link></p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentSuccess;
