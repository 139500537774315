import React from 'react';
import RestaurantBanner from './RestaurantSection/RestaurantBanner';
import RestaurantContent from './RestaurantSection/RestaurantContent';

function Restaurants() {
    return  (
    <div>
        <RestaurantBanner />
        <RestaurantContent />
    </div>
    )
}

export default Restaurants;
