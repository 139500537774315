import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function VillaTypePageContent(props) {

    const { dataProp } = props;
    const history = useHistory();
    const [villaType, setVillaType] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const villa_type_slug = dataProp.match.params.villa_type;
        axios.get(`/api/villa_type/${villa_type_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setVillaType(res.data.villa_type);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [dataProp.match.params.villa_type, history]);

    return  (
        <div className="PageArticle">
        {villaType.content && (
          <div>{parse( villaType.content ) }</div>
         )}
        </div>
  )
}

export default VillaTypePageContent;
