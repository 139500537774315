import React, { useEffect, useState } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

function SpaPageContent() {

    const [pageContent, setPageContent]   = useState([]);
  
    useEffect(() => {

      let isMounted = true;

      axios.get(`/api/page/spa`).then(res=>{
          if(isMounted)
          {
              if(res.status === 200)
              {
                  setPageContent(res.data.page)
              }
          }
      });

      return () => {
          isMounted = false
      };

    }, []);

    return  (
        <div className="PageArticle">
        {pageContent.content && (
          <div>{parse(pageContent.content)}</div>
         )}
        </div>
  )
}

export default SpaPageContent;
