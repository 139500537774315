import React from 'react';
import SearchBanner from './SearchSection/SearchBanner';
import SearchContent from './SearchSection/SearchContent';

function SearchResult(props) {
    return  (
    <div>
        <SearchBanner exampleProp={props}/>
        <SearchContent exampleProp={props}/>
    </div>
    )
}

export default SearchResult;
