import React, { useState, useRef } from 'react';
import videoBgImage from '../../assets/img/Mareakech-svg-1-05.svg';
import videoMp4 from '../../assets/video/intro.mp4';
import videoPlayIcon from '../../assets/img/video-icon.png';
import videoResumeIcon from '../../assets/img/resume-icon.png';
import videoPoster from '../../assets/img/video-poster.png';

function Video() {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const togglePlay = () => {
        if (videoRef.current) {
          if (isPlaying) {
            videoRef.current.pause();
          } else {
            videoRef.current.play();
          }
          setIsPlaying(!isPlaying);
        }
    };

    return  (
        <section className="video-block" 
            style={{ backgroundImage: `url(${videoBgImage})`, 
                    borderTop: '1px solid #A58A38',
                    borderBottom: '1px solid #A58A38' }}
            >
            <div className="container">
              <div 
              className="overflow-hidden ratios ratio-4x3s position-relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <video
                  ref={videoRef}
                  poster={videoPoster}
                  className="object-fit"
                >
                  <source src={videoMp4} type="video/mp4" />
                </video>
                <div class="play-button" onClick={togglePlay}>
                {!isPlaying && (
                  <img src={videoPlayIcon} alt={isPlaying ? "" : "Play Button"} />
                )}
                {isPlaying && isHovered && (
                    <i class="fa fa-pause" aria-hidden="true"></i>
                )}
                </div>
              </div>
            </div>
        </section>
    )
}

export default Video;
