import React from 'react';
import RestaurantBanner from './RestaurantSection/RestaurantBanner';
import RestaurantContentPagination from './RestaurantSection/RestaurantContentPagination';

function RestaurantPagination(props) {
    return  (
    <div>
        <RestaurantBanner />
        <RestaurantContentPagination exampleProp={props}/>
    </div>
    )
}

export default RestaurantPagination;
