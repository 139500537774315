import React from 'react';
import BlogDetailBreadCrumb from './BlogDetails/BlogDetailBreadCrumb';
import BlogDetailContent from './BlogDetails/BlogDetailContent';

function BlogDetail(props)
{
    return (
        <div>
         	<BlogDetailBreadCrumb exampleProp={props} />
            <BlogDetailContent exampleProp={props} />
        </div>
    )
}

export default BlogDetail;