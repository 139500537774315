import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function PartyPageContent(props) {

    const { dataProp } = props;
    const history = useHistory();
    const [partyType, setPartyType] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const party_type_slug = dataProp.match.params.party_type;
        axios.get(`/api/party_type/${party_type_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setPartyType(res.data.party_type);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [dataProp.match.params.party_type, history]);

    return  (
        <div className="PageArticle">
        {partyType.content && (
          <div>{parse( partyType.content ) }</div>
         )}
        </div>
  )
}

export default PartyPageContent;
