import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import MegaMenu from './MegaMenu';

const PrimaryMenu = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        if (document.getElementById('primary-menu').querySelector('ul.mobile-primary-menu')) {
          document.querySelectorAll('#primary-menu > ul.mobile-primary-menu, #primary-menu > div > ul.mobile-primary-menu').forEach(element => {
            element.classList.toggle('d-block');
          });
        } else {
          document.querySelectorAll('#primary-menu > ul, #primary-menu > div > ul').forEach(element => {
            element.classList.toggle('d-block');
          });
        }
        document.querySelector('.burger-icon').classList.toggle('d-none');
        document.querySelector('.close-icon').classList.toggle('d-none');
        document.body.classList.toggle('primary-menu-open');
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuClick = (el) => {
        window.location = el.currentTarget.getAttribute('href');
    };
   
	return (
        <div className="col-md-10">
            <nav id="primary-menu" className="with-arrows clearfix">
                <div className="clearfix">
                    <div id="primary-menu-trigger" onClick={handleMenuToggle}>
                        <i className="burger-icon fa-solid fa-bars"></i>
                        <i className="close-icon fa-solid fa-times d-none"></i>
                    </div>
                    <ul>
                        <li className="">
                            <Link to="/" onClick={handleMenuClick}>
                                <div>Home</div>
                            </Link>
                        </li>
                        
                        <li className="mega-menu sub-menu">
                            <Link to="/villas/" onClick={handleMenuClick}>
                                <div>Villas <i className="fa-solid fa-angle-down"></i></div>
                            </Link>
                            <MegaMenu />
                        </li>

                        <li className="">
                            <Link to="/restaurants/" onClick={handleMenuClick}>
                                <div>Restaurants</div>
                            </Link>
                        </li>

                        <li className="">
                            <Link to="/parties/night-life/" onClick={handleMenuClick}>
                                <div>Night Life</div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/parties/day-parties/" onClick={handleMenuClick}>
                                <div>Day Parties</div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/spas/" onClick={handleMenuClick}>
                                <div>Spa</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/golfs/" onClick={handleMenuClick}>
                                <div>Golf</div>
                            </Link>
                        </li>

                         <li className="">
                            <Link to="#">
                                <div>Excursions</div>
                            </Link>
                            <ul>
                                <li>
                                    <Link to="/transport-excursions/" onClick={handleMenuClick}>
                                        <div>Transport</div>
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="/hot-air-balloon/" onClick={handleMenuClick}>
                                        <div>Hot Air Balloon</div>
                                    </Link>
                                </li>
                               
                                <li>
                                    <Link to="/quad-bike/" onClick={handleMenuClick}>
                                        <div>Quad Bike</div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="">
                            <Link to="/fast-track/" onClick={handleMenuClick}>
                                <div>Fast Track</div>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/personalised-itinerary/" onClick={handleMenuClick}>
                                <div>Personalised Itineraries</div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
	);
}

export default PrimaryMenu;