import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function BlogBanner() {

    const [page, setPage] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/page/blog`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    return  (
     <div className="GrayHead py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1> {page.title}</h1>
            </div>
            <div className="col-md-5">
              <div className="BreadCrumbNav">
                <ul className="m-0 p-0">
                  <li><Link to="/">Home</Link> </li>
                  <li><span>Blog</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default BlogBanner;
