import React from 'react';
import FastTrackDetailBreadCrumb from './FastTrackDetails/FastTrackDetailBreadCrumb';
import FastTrackDetailContent from './FastTrackDetails/FastTrackDetailContent';

function FastTrackDetail(props)
{
    return (
      <div>
       	<FastTrackDetailBreadCrumb exampleProp={props} />
        <FastTrackDetailContent exampleProp={props} />
      </div>
    )
}

export default FastTrackDetail;