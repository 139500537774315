import React, {useEffect, useState} from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment'; // Add this import
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns'; // Import the format function

function FastTrackDetailContent(props)
{
    const { exampleProp }                           = props;
    const history                                   = useHistory();
    const [product, setFastTrack]                   = useState([]);
    
    const [productName, setProductName]             = useState('');
    const [productImage, setProductImage]           = useState('');
    const [productChildPrice, setProductChildPrice] = useState(0);
    const [productAdultPrice, setProductAdultPrice] = useState(0);
    const [productSlug, setProductSlug]             = useState('');
    const [adultCount, setAdultCount]               = useState(1);
    const [childCount, setChildCount]               = useState(0);
    const [leadPassengerName, setLeadPassengerName] = useState('');
    const [phoneNumber, setPhoneNumber]             = useState('');
    const [airline, setAirline]                     = useState('');
    const [flightNo, setFlightNo]                   = useState('');
    const [departureDate, setDepartureDate]         = useState('');
    const [departureTime, setDepartureTime]         = useState("12:00 PM"); // Set initial time to 12:00 PM
    const [errorlist, setError]                     = useState([]);
    const [countryCode, setCountryCode]             = useState('+44');

    useEffect(() => {
        let isMounted = true;
        const product_slug = exampleProp.match.params.slug;
        axios.get(`/api/product/${product_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                  setFastTrack(res.data.product);
                  setProductName(res.data.product.title);
                  setProductImage(res.data.product.featured_image);
                  setProductAdultPrice(res.data.product.adult_price);
                  setProductChildPrice(res.data.product.child_price);
                  setProductSlug(res.data.product.slug)
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    // New function to handle date range selection
    const handleDateChange = (date) => {
      setDepartureDate(date);
    };

    const handleFormSubmit = (event) => {
      event.preventDefault();
      let totalChildPrice = parseFloat(productChildPrice) * parseFloat(childCount);
      let totalAdultPrice = parseFloat(productAdultPrice) * parseFloat(adultCount);
      let totalPriceCal = totalChildPrice + totalAdultPrice;
      let totalPrice    = totalPriceCal.toFixed(2); // This will round the number to 2 decimal places
      let departureData = format(departureDate, 'dd/MM/yyyy');
      let mobileNumber  = countryCode + phoneNumber;
      const item = {
        productName,
        productImage,
        productChildPrice,
        productAdultPrice,
        productSlug,
        adultCount,
        childCount,
        leadPassengerName,
        mobileNumber,
        airline,
        flightNo,
        departureData,
        departureTime,
        totalPrice,
      };
      axios.post(`/api/product/cart/validation`, item).then(res=>{
          if(res.data.status === 200)
          {
            localStorage.setItem('cartItem', JSON.stringify(item));
            history.push('/cart');
          }
          else if(res.data.status === 422)
          {
              setError(res.data.errors);
          }
      });
    };

    return (
      <section className="ListSectinoDetail ProductDetail">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mt-5">
              {product.featured_image && (
                <figure>
                  <img src={product.featured_image} alt={product.title} className="img-fluid w-100" />
                </figure>
              )}
            </div>
            <div className="col-md-5 mt-5">
              <div className="RightCol">
                <h2 className="ProductTitle">{product.title}</h2>
                <span className="Cost">£{product.adult_price} per person (half price for child) </span>
                <div className="FormHolder">
                  <form onSubmit={handleFormSubmit}>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>ADULT (AGES 12 AND ABOVE)</label>
                      <select className="form-select" value={adultCount} onChange={(e) => setAdultCount(parseInt(e.target.value))}>
                        {[...Array(10).keys()].map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </select>
                      {errorlist.adultCount && (
                        <small className="text-danger">{errorlist.adultCount}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"> CHILD (AGES 2-12)</label>
                      <select className="form-select" value={childCount} onChange={(e) => setChildCount(parseInt(e.target.value))}>
                        {[...Array(10).keys()].map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </select>
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>LEAD PASSENGER FULL NAME</label>
                      <input type="text" className="form-control" value={leadPassengerName} onChange={(e) => setLeadPassengerName(e.target.value)} />
                      {errorlist.leadPassengerName && (
                        <small className="text-danger">{errorlist.leadPassengerName}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label">
                            <span>*</span>Country Code
                          </label>
                          <select 
                              className="form-select" 
                              value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                              <option value="+376">AD (+376)</option>
                              <option value="+971">AE (+971)</option>
                              <option value="+93">AF (+93)</option>
                              <option value="+1268">AG (+1268)</option>
                              <option value="+1264">AI (+1264)</option>
                              <option value="+355">AL (+355)</option>
                              <option value="+374">AM (+374)</option>
                              <option value="+599">AN (+599)</option>
                              <option value="+244">AO (+244)</option>
                              <option value="+672">AQ (+672)</option>
                              <option value="+54">AR (+54)</option>
                              <option value="+1684">AS (+1684)</option>
                              <option value="+43">AT (+43)</option>
                              <option value="+61">AU (+61)</option>
                              <option value="+297">AW (+297)</option>
                              <option value="+994">AZ (+994)</option>
                              <option value="+387">BA (+387)</option>
                              <option value="+1246">BB (+1246)</option>
                              <option value="+880">BD (+880)</option>
                              <option value="+32">BE (+32)</option>
                              <option value="+226">BF (+226)</option>
                              <option value="+359">BG (+359)</option>
                              <option value="+973">BH (+973)</option>
                              <option value="+257">BI (+257)</option>
                              <option value="+229">BJ (+229)</option>
                              <option value="+590">BL (+590)</option>
                              <option value="+1441">BM (+1441)</option>
                              <option value="+673">BN (+673)</option>
                              <option value="+591">BO (+591)</option>
                              <option value="+55">BR (+55)</option>
                              <option value="+1242">BS (+1242)</option>
                              <option value="+975">BT (+975)</option>
                              <option value="+267">BW (+267)</option>
                              <option value="+375">BY (+375)</option>
                              <option value="+501">BZ (+501)</option>
                              <option value="+1">CA (+1)</option>
                              <option value="+61">CC (+61)</option>
                              <option value="+243">CD (+243)</option>
                              <option value="+236">CF (+236)</option>
                              <option value="+242">CG (+242)</option>
                              <option value="+41">CH (+41)</option>
                              <option value="+225">CI (+225)</option>
                              <option value="+682">CK (+682)</option>
                              <option value="+56">CL (+56)</option>
                              <option value="+237">CM (+237)</option>
                              <option value="+86">CN (+86)</option>
                              <option value="+57">CO (+57)</option>
                              <option value="+506">CR (+506)</option>
                              <option value="+53">CU (+53)</option>
                              <option value="+238">CV (+238)</option>
                              <option value="+61">CX (+61)</option>
                              <option value="+357">CY (+357)</option>
                              <option value="+420">CZ (+420)</option>
                              <option value="+49">DE (+49)</option>
                              <option value="+253">DJ (+253)</option>
                              <option value="+45">DK (+45)</option>
                              <option value="+1767">DM (+1767)</option>
                              <option value="+1809">DO (+1809)</option>
                              <option value="+213">DZ (+213)</option>
                              <option value="+593">EC (+593)</option>
                              <option value="+372">EE (+372)</option>
                              <option value="+20">EG (+20)</option>
                              <option value="+291">ER (+291)</option>
                              <option value="+34">ES (+34)</option>
                              <option value="+251">ET (+251)</option>
                              <option value="+358">FI (+358)</option>
                              <option value="+679">FJ (+679)</option>
                              <option value="+500">FK (+500)</option>
                              <option value="+691">FM (+691)</option>
                              <option value="+298">FO (+298)</option>
                              <option value="+33">FR (+33)</option>
                              <option value="+241">GA (+241)</option>
                              <option value="+44">GB (+44)</option>
                              <option value="+1473">GD (+1473)</option>
                              <option value="+995">GE (+995)</option>
                              <option value="+233">GH (+233)</option>
                              <option value="+350">GI (+350)</option>
                              <option value="+299">GL (+299)</option>
                              <option value="+220">GM (+220)</option>
                              <option value="+224">GN (+224)</option>
                              <option value="+240">GQ (+240)</option>
                              <option value="+30">GR (+30)</option>
                              <option value="+502">GT (+502)</option>
                              <option value="+1671">GU (+1671)</option>
                              <option value="+245">GW (+245)</option>
                              <option value="+592">GY (+592)</option>
                              <option value="+852">HK (+852)</option>
                              <option value="+504">HN (+504)</option>
                              <option value="+385">HR (+385)</option>
                              <option value="+509">HT (+509)</option>
                              <option value="+36">HU (+36)</option>
                              <option value="+62">ID (+62)</option>
                              <option value="+353">IE (+353)</option>
                              <option value="+972">IL (+972)</option>
                              <option value="+44">IM (+44)</option>
                              <option value="+91">IN (+91)</option>
                              <option value="+964">IQ (+964)</option>
                              <option value="+98">IR (+98)</option>
                              <option value="+354">IS (+354)</option>
                              <option value="+39">IT (+39)</option>
                              <option value="+1876">JM (+1876)</option>
                              <option value="+962">JO (+962)</option>
                              <option value="+81">JP (+81)</option>
                              <option value="+254">KE (+254)</option>
                              <option value="+996">KG (+996)</option>
                              <option value="+855">KH (+855)</option>
                              <option value="+686">KI (+686)</option>
                              <option value="+269">KM (+269)</option>
                              <option value="+1869">KN (+1869)</option>
                              <option value="+850">KP (+850)</option>
                              <option value="+82">KR (+82)</option>
                              <option value="+965">KW (+965)</option>
                              <option value="+1345">KY (+1345)</option>
                              <option value="+7">KZ (+7)</option>
                              <option value="+856">LA (+856)</option>
                              <option value="+961">LB (+961)</option>
                              <option value="+1758">LC (+1758)</option>
                              <option value="+423">LI (+423)</option>
                              <option value="+94">LK (+94)</option>
                              <option value="+231">LR (+231)</option>
                              <option value="+266">LS (+266)</option>
                              <option value="+370">LT (+370)</option>
                              <option value="+352">LU (+352)</option>
                              <option value="+371">LV (+371)</option>
                              <option value="+218">LY (+218)</option>
                              <option value="+212">MA (+212)</option>
                              <option value="+377">MC (+377)</option>
                              <option value="+373">MD (+373)</option>
                              <option value="+382">ME (+382)</option>
                              <option value="+1599">MF (+1599)</option>
                              <option value="+261">MG (+261)</option>
                              <option value="+692">MH (+692)</option>
                              <option value="+389">MK (+389)</option>
                              <option value="+223">ML (+223)</option>
                              <option value="+95">MM (+95)</option>
                              <option value="+976">MN (+976)</option>
                              <option value="+853">MO (+853)</option>
                              <option value="+1670">MP (+1670)</option>
                              <option value="+222">MR (+222)</option>
                              <option value="+1664">MS (+1664)</option>
                              <option value="+356">MT (+356)</option>
                              <option value="+230">MU (+230)</option>
                              <option value="+960">MV (+960)</option>
                              <option value="+265">MW (+265)</option>
                              <option value="+52">MX (+52)</option>
                              <option value="+60">MY (+60)</option>
                              <option value="+258">MZ (+258)</option>
                              <option value="+264">NA (+264)</option>
                              <option value="+687">NC (+687)</option>
                              <option value="+227">NE (+227)</option>
                              <option value="+234">NG (+234)</option>
                              <option value="+505">NI (+505)</option>
                              <option value="+31">NL (+31)</option>
                              <option value="+47">NO (+47)</option>
                              <option value="+977">NP (+977)</option>
                              <option value="+674">NR (+674)</option>
                              <option value="+683">NU (+683)</option>
                              <option value="+64">NZ (+64)</option>
                              <option value="+968">OM (+968)</option>
                              <option value="+507">PA (+507)</option>
                              <option value="+51">PE (+51)</option>
                              <option value="+689">PF (+689)</option>
                              <option value="+675">PG (+675)</option>
                              <option value="+63">PH (+63)</option>
                              <option value="+92">PK (+92)</option>
                              <option value="+48">PL (+48)</option>
                              <option value="+508">PM (+508)</option>
                              <option value="+870">PN (+870)</option>
                              <option value="+1">PR (+1)</option>
                              <option value="+351">PT (+351)</option>
                              <option value="+680">PW (+680)</option>
                              <option value="+595">PY (+595)</option>
                              <option value="+974">QA (+974)</option>
                              <option value="+40">RO (+40)</option>
                              <option value="+381">RS (+381)</option>
                              <option value="+7">RU (+7)</option>
                              <option value="+250">RW (+250)</option>
                              <option value="+966">SA (+966)</option>
                              <option value="+677">SB (+677)</option>
                              <option value="+248">SC (+248)</option>
                              <option value="+249">SD (+249)</option>
                              <option value="+46">SE (+46)</option>
                              <option value="+65">SG (+65)</option>
                              <option value="+290">SH (+290)</option>
                              <option value="+386">SI (+386)</option>
                              <option value="+421">SK (+421)</option>
                              <option value="+232">SL (+232)</option>
                              <option value="+378">SM (+378)</option>
                              <option value="+221">SN (+221)</option>
                              <option value="+252">SO (+252)</option>
                              <option value="+597">SR (+597)</option>
                              <option value="+239">ST (+239)</option>
                              <option value="+503">SV (+503)</option>
                              <option value="+963">SY (+963)</option>
                              <option value="+268">SZ (+268)</option>
                              <option value="+1649">TC (+1649)</option>
                              <option value="+235">TD (+235)</option>
                              <option value="+228">TG (+228)</option>
                              <option value="+66">TH (+66)</option>
                              <option value="+992">TJ (+992)</option>
                              <option value="+690">TK (+690)</option>
                              <option value="+670">TL (+670)</option>
                              <option value="+993">TM (+993)</option>
                              <option value="+216">TN (+216)</option>
                              <option value="+676">TO (+676)</option>
                              <option value="+90">TR (+90)</option>
                              <option value="+1868">TT (+1868)</option>
                              <option value="+688">TV (+688)</option>
                              <option value="+886">TW (+886)</option>
                              <option value="+255">TZ (+255)</option>
                              <option value="+380">UA (+380)</option>
                              <option value="+256">UG (+256)</option>
                              <option value="+1">US (+1)</option>
                              <option value="+598">UY (+598)</option>
                              <option value="+998">UZ (+998)</option>
                              <option value="+39">VA (+39)</option>
                              <option value="+1784">VC (+1784)</option>
                              <option value="+58">VE (+58)</option>
                              <option value="+1284">VG (+1284)</option>
                              <option value="+1340">VI (+1340)</option>
                              <option value="+84">VN (+84)</option>
                              <option value="+678">VU (+678)</option>
                              <option value="+681">WF (+681)</option>
                              <option value="+685">WS (+685)</option>
                              <option value="+381">XK (+381)</option>
                              <option value="+967">YE (+967)</option>
                              <option value="+262">YT (+262)</option>
                              <option value="+27">ZA (+27)</option>
                              <option value="+260">ZM (+260)</option>
                              <option value="+263">ZW (+263)</option>
                          </select>
                        </div>
                        <div className="col-md-8">
                          <label className="form-label">
                            <span>*</span>MOBILE NUMBER
                          </label>
                          <input type="text" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                          {errorlist.mobileNumber && (
                            <small className="text-danger">{errorlist.mobileNumber}</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>AIRLINE</label>
                      <input type="text" className="form-control" value={airline} onChange={(e) => setAirline(e.target.value)} />
                      {errorlist.airline && (
                        <small className="text-danger">{errorlist.airline}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>FLIGHT NO.</label>
                      <input type="text" className="form-control" value={flightNo} onChange={(e) => setFlightNo(e.target.value)} />
                      {errorlist.flightNo && (
                        <small className="text-danger">{errorlist.flightNo}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      {product.category_slug === 'fast-track-departure-services-options' ? (
                        <label className="form-label"><span>*</span>Departure DATE</label>
                       ) : (product.category_slug &&
                         <label className="form-label"><span>*</span>Arrival DATE</label>
                      )}
                      <DatePicker
                        selected={departureDate}
                        onChange={handleDateChange}
                        className="form-control"
                        placeholderText="Date*"
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)}
                      />
                      {errorlist.departureDate && (
                        <small className="text-danger">{errorlist.departureDate}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      {product.category_slug === 'fast-track-departure-services-options' ? (
                        <label className="form-label"><span>*</span>Departure TIME</label>
                       ) : (product.category_slug &&
                         <label className="form-label"><span>*</span>Arrival TIME</label>
                      )}
                       <TimePicker 
                          use12Hours={true}
                          showSecond={false}
                          className="form-control"
                          placeholder="Select Time"
                          onChange={(time) => setDepartureTime(time && time.format('h:mm A'))} // Update state with formatted time
                      />

                      {errorlist.departureTime && (
                        <small className="text-danger">{errorlist.departureTime}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <button type="submit" className="btn btn-warning BtnOrange">Add To Basket</button>
                    </div>
                  </form>
                  <div className="product_meta mt-3">
                    <span className="posted_in">Category: <span className="text-orange">{product.category_name}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {product.content && (
          <div className="row">
            <div className="col-md-12">
              <div className="DetailTab mt-5">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-description-tab" data-bs-toggle="tab" data-bs-target="#nav-description" type="button" role="tab" aria-controls="nav-description" aria-selected="true"><i className="fa-solid fa-circle-info"></i> <span>Description</span></button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-description" role="tabpanel" aria-labelledby="nav-description-tab" tabindex="0">
                    <div className="Info">
                      {parse(product.content)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </section>
    )
}

export default FastTrackDetailContent;