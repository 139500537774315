import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

const Footer = () => {
    const [setting, setSetting] = useState([]);
    const [cookieExists, setCookieExists] = useState(true);

    // const handleClick = () => {
    //     document.cookie = "myCookie=cookieValue;path=/";
    //     setCookieExists(false);
    // };

    // const handleCloseClick = () => {
    //     setCookieExists(false);
    // };

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/setting`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setSetting(res.data.setting);
                }
            }
        });

          const cookies = document.cookie.split(';');
            const cookieMap = {};
            
            // Parse the cookies into a map for easier access
            cookies.forEach(cookie => {
              const [name, value] = cookie.trim().split('=');
              cookieMap[name] = value;
            });

            // Check if the cookie key exists and set the state accordingly
            if (cookieMap['myCookie']) {
              setCookieExists(false);
            }


        return () => {
            isMounted = false
        };

    }, []);
    
    return (
        <>
        <footer className="FooterMain">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="Box">
                            <h5 className="mb-3">Contact Us</h5>
                            <p>Address:</p>
                             {setting.address && (
                                <p>{ parse( setting.address ) }</p>
                             )}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="Box">
                            <h5 className="mb-3">USEFUL LINKS </h5>
                            <ul className="m-0 p-0">
                                <li><Link to="/about/">About</Link></li>
                                <li><Link to="/blogs/">Blog</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="Box">
                            <h5 className="mb-3">Help </h5>
                            <ul className="m-0 p-0">
                                <li><Link to="/contact/">Contact</Link></li>
                                <li><Link to="/faq/">FAQ</Link></li>
                                <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
                                <li><Link to="/terms-conditions/">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="Box">
                            <h5 className="mb-3">BE SOCIAL </h5>
                            <p>Join the Conversation </p>
                            <div className="SocialLinks">
                                {setting.facebook_url && (
                                    <a href={setting.facebook_url} target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                )}
                                {setting.instagram_url && (
                                    <a href={setting.instagram_url} target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                )}
                                {setting.youtube_url && (
                                    <a href={setting.youtube_url} target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-youtube"></i>
                                    </a>
                                )}
                              
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="Box TextRight">
                            <p>© Marrakech Concierge {setting.current_year}. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
}

export default Footer;
