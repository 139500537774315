import React from 'react';

function ContactBreadCrumb()
{
    return (
     <div className="GrayHead py-2">
	    <div className="container">
	      <div className="row">
	        <div className="col-md-7">
	          <h1>Contact</h1>
	        </div>
	        <div className="col-md-5">
	          <div className="BreadCrumbNav">
	            <ul className="m-0 p-0">
	              <li><a href="/">Home</a></li>
	              <li><span>Contact</span></li>
	            </ul>
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
    )
}

export default ContactBreadCrumb;
