import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

const HeaderLogo = () => {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/setting`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setSetting(res.data.setting);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);
    const handleMenuClick = (el) => {
        window.location = el.currentTarget.getAttribute('href');
    };
	return (
        <div className="col-md-2">
            <div className="logo">
                <Link to="/" onClick={handleMenuClick}>
                 {setting.logo && (
	                <img src={setting.logo} className="img-fluid" alt="Logo" loading="lazy"/>
	              )}	
                </Link>
            </div>
        </div>
	);
}

export default HeaderLogo;