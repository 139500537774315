import React from 'react';
import GolfBanner from './GolfSection/GolfBanner';
import GolfContentPagination from './GolfSection/GolfContentPagination';

function GolfPagination(props) {
    return  (
    <div>
        <GolfBanner />
        <GolfContentPagination exampleProp={props}/>
    </div>
    )
}

export default GolfPagination;
