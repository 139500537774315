import React from 'react';
import ContactBreadCrumb from './ContactSection/ContactBreadCrumb';
import ContactForm from './ContactSection/ContactForm';

function Contact() {
    return  (
    <section class="ContactPage">
        <ContactBreadCrumb />
        <ContactForm />
    </section>
    )
}

export default Contact;
