import React, { useEffect, useState } from 'react';
import axios from 'axios';


const TopHeader = () => {
    const [setting, setSetting]         = useState([]);
    const [moroccoTime, setMoroccoTime] = useState('');
    const [moroccoDate, setMoroccoDate] = useState('');
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/setting`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setSetting(res.data.setting);
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);

    useEffect(() => {
        const getMoroccoTime = () => {
          const currentDate = new Date();
      
          // Set the time zone to Morocco (Western European Time)
          const moroccoTimeDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Africa/Casablanca" }));

          // Extract day, month, and year
          const day = moroccoTimeDate.getDate();
          const month = moroccoTimeDate.toLocaleString('en-US', { month: 'long' });
          const year = moroccoTimeDate.getFullYear();

          // Format the time
          const time = moroccoTimeDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit'});

          // Construct the formatted date string
          const formattedMoroccoTime = `${time}`;
          const formattedMoroccoDate = `${day} ${month} ${year}`;
          setMoroccoTime(formattedMoroccoTime);
          setMoroccoDate(formattedMoroccoDate);
        };

        getMoroccoTime();
        const intervalId = setInterval(getMoroccoTime,60000);
        return () => clearInterval(intervalId);
    }, []);

     useEffect(() => {
        const openWeatherMap = 'https://api.openweathermap.org/data/2.5/weather';
        const apiKey = 'b4c7a68e2dcf64b94ecaf09a93123bd1'; // Replace with your OpenWeatherMap API key

        const fetchData = async () => {
          try {
            const response = await fetch(`${openWeatherMap}?lat=31.634545&lon=-8.1479382&appid=${apiKey}`);
            const data = await response.json();

            if (response.ok) {
              setWeatherData(data);
            } else {
              console.error('Failed to fetch weather data:', data.message);
            }
          } catch (error) {
            console.error('Error fetching weather data:', error);
          }
        };

        fetchData();
      }, []);
     var celsius;
     if( weatherData ){
        celsius = weatherData.main.temp - 273.15; 
     }else{
        celsius = 13;
     }
     
	 return (
        <div className="top-header  d-sm-block">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="lang-dropdown">
                            <div id="google_translate_element"></div>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="dropdown d-lg-flex">
                          <button className="dropdbtn d-flex">Marrakech <i className="fas fa-angle-down "></i></button>
                          <div className="dropdown-content">
                            <div className="date-weather">
                             <ul>
                                <li>Time {moroccoTime} </li>
                                <li>{moroccoDate} </li>
                                {celsius && (
                                <li><i className="bi bi-cloud-sun"></i>{celsius.toFixed(2)}<sup>o</sup>C</li>
                                )}
                            </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-right">
                        <div className="top-social">
                            <ul>
                                {setting.facebook_url && (
                                    <li>
                                        <a href={setting.facebook_url} target="_blank" rel="noreferrer">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </a>
                                    </li>
                                )}
                                {setting.instagram_url && (
                                    <li>
                                        <a href={setting.instagram_url} target="_blank" rel="noreferrer">
                                            <i className="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                )}
                                {setting.youtube_url && (
                                    <li>
                                        <a href={setting.youtube_url} target="_blank" rel="noreferrer">
                                            <i className="fa-brands fa-youtube"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default TopHeader;