import React from 'react';
import {Route} from 'react-router-dom';
import MasterLayout from './layouts/MasterLayout';

function PublicRoute({...rest})
{
    return (
        <Route {...rest} render={ (props) => <MasterLayout {...props} /> } />
    )
}

export default PublicRoute;
