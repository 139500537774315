import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link,useHistory} from 'react-router-dom';

import locationImage from '../../assets/img/icons/location.svg';

function SearchContent(props) {
  const { exampleProp } = props;
    const history = useHistory();

    const [seachList, setSearch] = useState([]);
    
    useEffect(() => {
        let isMounted = true;
        const search_slug = exampleProp.match.params.slug;
        // alert(search_slug);
        const module_slug  = exampleProp.match.params.module;
        if (typeof search_slug === 'undefined' || search_slug === ''){
          axios.get(`/api/search/${module_slug}`).then(res=>{
            if(isMounted)
            {
              if(res.data.status === 200)
              {
                setSearch(res.data.search);
              }
            }
        });  
        } else{
          axios.get(`/api/search/${module_slug}/${search_slug}`).then(res=>{
            if(isMounted)
            {
              if(res.data.status === 200)
              {
                setSearch(res.data.search);
              }
            }
        });
        }
        

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.module,exampleProp.match.params.slug, history]);

  
    return  (
    <section className="GridSection">
      <div className="container">
        <div className="row">
         { seachList.map((item) => ( 
          <div key={item.id}  className="col-md-4 mt-4">
            <div className="grid-box shadow bg-white rounded">
              <div className="Holder position-relative bg-black"> 
                <Link to={`${item.permalink}`} className="HolderOverlay d-block position-relative"> 
                  {item.featured_image && (
                    <img src={item.featured_image} alt={item.title} className="img-fluid" loading="lazy"/> 
                  )}
                </Link> 
              </div>
              <div className="grid-content">
                <Link to={`${item.permalink}`}>
                  <h3 className="fw-bold m-0 mt-3">{item.title}</h3>
                </Link>
                {item.content && (
                  <p>{item.content} <Link to={`${item.permalink}`} className="btnLogoColor">more</Link></p>
                )}
              </div>
              <div className="divider">
                <hr />
              </div>

              <div className="box-footer">
                <ul>
                  {item.location && (
                    <li>
                      <img src={locationImage} /> <span>{item.location}</span>
                    </li>
                  )}
                </ul> 
                <Link to={`${item.permalink}`} className="BtnOrange mt-2 d-inline-block">
                  Enquire Now
                </Link>
              </div>
            </div>
          </div>
        ))}
        </div>
     
     
      </div>
    </section>
  )
}

export default SearchContent;
