import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import VillaTypePageContent from './VillaTypePageContent';
import locationImage from '../../assets/img/icons/location.svg';

function VillaTypeContent(props) {

    const { exampleProp }                 = props;
    const history                         = useHistory();
    const [villaType, setVillaType]       = useState([]);
    const [pageNumber, setPageNumber]     = useState([]);
    const [currentPage, setcurrentPage]   = useState([]);

    useEffect(() => {
        let isMounted = true;
        const villa_type_slug = exampleProp.match.params.villa_type;
      
        axios.get(`/api/villa_type/${villa_type_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setVillaType(res.data.villa);
                    setPageNumber(res.data.page_number)
                    setcurrentPage(res.data.current_page)
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, [exampleProp.match.params.villa_type, history]);

    const pages = Array.from({ length: pageNumber }, (_, index) => index + 1);
    return  (
    <section className="GridSection">
      <div className="container">
        <div className="row">
         { villaType.map((item) => ( 
          <div className="col-md-4 mt-4">
            <div className="grid-box shadow bg-white rounded">
              <div className="Holder position-relative bg-black"> 
                <Link to={`/villa/${item.slug}/`} className="HolderOverlay d-block position-relative"> 
                  {item.featured_image && (
                    <img src={item.featured_image} alt={item.title} className="img-fluid" loading="lazy"/> 
                  )}
                </Link> 
              </div>
              <div className="grid-content">
                <Link to={`/villa/${item.slug}/`}>
                  <h3 className="fw-bold m-0 mt-3">{item.title}</h3>
                </Link>
                {item.content && (
                  <p>{item.content} <Link to={`/villa/${item.slug}/`} className="btnLogoColor">more</Link></p>
                )}
              </div>
              <div className="divider">
                <hr />
              </div>

              <div className="box-footer">
                <ul>
                  {item.location && (
                    <li>
                      <img src={locationImage} /> <span>{item.location}</span>
                    </li>
                   )}
                </ul>
                
                <Link to={`/villa/${item.slug}/`} className="BtnOrange mt-2 d-inline-block">
                  Enquire Now
                </Link>
          
              </div>
            </div>
          </div>
        ))}
        </div>
        <div className="PaginationNav mt-5">
        {pageNumber > 1 && (
          <nav>
            <ul className="pagination">
              {pages.map((page) => (
                <li key={page} className={`page-item ${currentPage === page ? 'active' : '' }`} aria-current={page === 1 ? 'page' : undefined}>
                  <Link to={`/villa-type/${exampleProp.match.params.villa_type}/page/${page}/`} className="page-link">
                    {page}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
        </div>
        <VillaTypePageContent dataProp={exampleProp} />
      </div>
    </section>
  )
}

export default VillaTypeContent;
