import React from 'react';
import GolfBanner from './GolfSection/GolfBanner';
import GolfContent from './GolfSection/GolfContent';

function Golfs() {
    return  (
    <div>
        <GolfBanner />
        <GolfContent />
    </div>
    )
}

export default Golfs;
