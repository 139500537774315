import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function PageGallery(props)
{
    const { exampleProp }         = props;
    const history                 = useHistory();
    const [page, setPage]         = useState([]);
    const [galleries, setGallery] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const page_slug = exampleProp.match.params.page_slug;
        axios.get(`/api/page/detail/${page_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setPage(res.data.page);
                    setGallery(res.data.galleries);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.page_slug, history]);
   
    return (
      <div>
        {page.gallery_type === 'slider' ? (
          <div className="col-md-12 my-4">
            <section className="Banner">
              <div 
                id="carouselExampleAutoplaying" 
                className="carousel slide" 
                data-bs-ride="carousel">
                <div className="carousel-inner">
                    { galleries.map((item) => ( 
                        <div key={item.id} className={`carousel-item ${item.active}`}>
                          <img className="d-block w-100" src={item.url} alt={item.id} loading="lazy" /> 
                        </div>
                    ))}                  
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button>
              </div>
            </section>
          </div>
        ) : (
          galleries && (
            <div className="col-md-12 mt-5">
              <div className="GalleryItem">
                <div className="row">
                  {galleries.map((item) => (
                    <div className="col-md-4" key={item.id}>
                      <figure className="figure">
                        <img src={item.url} className="d-block w-100" alt={`Slider ${item.id}`} loading="lazy" />
                      </figure>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );

}

export default PageGallery;