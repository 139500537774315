import React from 'react';
import FastTrackDetailBreadCrumb from './FastTrackDetails/FastTrackDetailBreadCrumb';
import FastTrackEditDetailContent from './FastTrackDetails/FastTrackEditDetailContent';

function FastTrackEditDetail(props)
{
    return (
      <div>
       	<FastTrackDetailBreadCrumb exampleProp={props} />
        <FastTrackEditDetailContent exampleProp={props} />
      </div>
    )
}

export default FastTrackEditDetail;