import React, { useEffect, useState } from 'react';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const Checkout = () => {
    const [page, setPage] = useState([]);
    const [clientSecret, setClientSecret] = useState("");
    const stripePromise = loadStripe('pk_live_XjauX4Zl8AuSR1r02JBlNLr900PdxZMNCR');
    
    useEffect(() => {
        fetchClientSecret();
    }, []);

    const fetchClientSecret = async () => {
        try {
            // Fetch cart item from localStorage
            const cartItemFromStorage = localStorage.getItem('cartItem');
            const cartItem = JSON.parse(cartItemFromStorage);
          
            const response = await axios.post(`/api/order/pay`, {
                cartItem,
            });
            setClientSecret(response.data.clientSecret);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/page/checkout`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, []);

    return (
        <div>
            <section
                className="Banner IBanner position-relative"
                style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
            >
                <div className="PageHead">
                    <h1 className="text-uppercase text-white"> {page.title} </h1>
                    <ol className="breadcrumb d-block">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/checkout/"> {page.title} </Link>
                        </li>
                    </ol>
                </div>
            </section>

            <section className="CheckoutPage">
                <div className="container">
                    <Elements stripe={stripePromise}>
                        <CheckoutForm clientSecret={clientSecret} />
                    </Elements>
                </div>
            </section>
        </div>
    );
};

export default Checkout;
