import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const Cart = () => {
  const [cartItem, setCartItem]   = useState('');
  const [page, setPage] = useState([]);

  useEffect(() => {
      let isMounted = true;
      axios.get(`/api/page/cart`).then(res=>{
          if(isMounted)
          {
              if(res.data.status === 200)
              {
                  setPage(res.data.page);
              }
          }
      });
      // Fetch cart item from localStorage
      const cartItemFromStorage = localStorage.getItem('cartItem');

      // If cart item exists in localStorage, parse it into an object
      if (cartItemFromStorage) {
        try {
          const parsedCartItem = JSON.parse(cartItemFromStorage);
          setCartItem(parsedCartItem);
        } catch (error) {
          console.error('Error parsing cart item:', error);
        }
      }

      return () => {
          isMounted = false
      };
  }, []);
  
  const handleDeleteClick = () => {
    localStorage.removeItem('cartItem');
    setCartItem('');
  }

  return (
    <div>
      <section 
          className="Banner IBanner position-relative"
          style={page.featured_image ? { backgroundImage: `url(${page.featured_image})` } : {}}
      >
        <div className="PageHead">
          <h1 className="text-uppercase text-white"> {page.title} </h1>
          <ol className="breadcrumb d-block">
            <li className="breadcrumb-item"> 
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active"> 
              <Link to="/cart/"> {page.title} </Link>
            </li>
          </ol>
        </div>
      </section>

      <section className="CartPage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive mt-5">
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th className="ThumbPic"></th>
                      <th className="ProductName">Product</th>
                      <th className="Price">Price</th>
                      <th className="SubTotal">Subtotal</th>
                      <th className="Action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItem ? (
                      <tr>
                        <td className="ThumbPic">
                          <div className="PicHolder">
                            {cartItem.productImage && (
                              <img src={cartItem.productImage} alt={cartItem.productName} className="img-fluid w-100" />
                            )}
                          </div>
                        </td>
                        <td className="ProductName">
                            <div className="ProductInfo">
                            <h4>
                              <Link to={`/product/${cartItem.productSlug}/`}>{cartItem.productName}</Link>
                            </h4>
                            <ul className="ProductMeta">

                              <li>Adult (Ages 12 and above):<span> {cartItem.adultCount} * £{cartItem.productAdultPrice}</span> </li>
                              <li> Child (Ages 2-12):<span> {cartItem.childCount} * £{cartItem.productChildPrice} </span></li>
                              <li> Lead Passenger Full Name:<span> {cartItem.leadPassengerName} </span></li>
                              <li> Mobile Number (Incl. Country Code) :<span> {cartItem.mobileNumber} </span></li>
                              <li> Airline:<span> {cartItem.airline} </span></li>
                              <li> Flight No.:<span> {cartItem.flightNo}</span> </li>
                              <li> Arrival Date:<span> {cartItem.departureData} </span></li>
                              <li>Arrival Time:<span> {cartItem.departureTime} </span></li>
                            </ul>
                          </div>
                        </td>
                        <td className="Price"><span className="SubTotal">£{cartItem.totalPrice}</span></td>
                        <td className="SubTotal">£{cartItem.totalPrice}</td>
                        <td className="Action">
                          <Link to={`/product/${cartItem.productSlug}/edit-cart/`} className="Edit">
                            <i className="fa-solid fa-pen-to-square"></i>
                          </Link> 
                          <a href="javascript:void(0)" className="Delete" onClick={handleDeleteClick}>
                            <i className="fa-solid fa-xmark"></i>
                          </a>
                        </td>
                      </tr>
                    ): (
                      <tr>
                        <td colSpan="5">Your basket is currently empty.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {cartItem && (
                <div className="BasketTotal">
                  <h5>Basket Totals</h5>
                  <ul>
                    <li><span className="SubTitle">Subtotal</span> <span>£{cartItem.totalPrice}</span></li>
                    <li><span className="SubTitle">Total</span> <span>£{cartItem.totalPrice}</span></li>
                  </ul>
                  <Link to="/checkout/" className="btn BtnOrange m-3 d-block">Proceed to checkout</Link> 
                </div>
              )}
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Cart;
