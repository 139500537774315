import React from 'react';
import VillaTypeBanner from './VillaTypeSection/VillaTypeBanner';
import VillaTypeContentPagination from './VillaTypeSection/VillaTypeContentPagination';

function VillaTypePagination(props) {
    return  (
    <div>
        <VillaTypeBanner exampleProp={props} />
        <VillaTypeContentPagination exampleProp={props} />
    </div>
    )
}

export default VillaTypePagination;
