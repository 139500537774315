import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

function RestaurantDetailBreadCrumb(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    
    const [restaurant, setRestaurant] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const restaurant_slug = exampleProp.match.params.slug;
 
        axios.get(`/api/restaurant/${restaurant_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setRestaurant(res.data.restaurant);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    return (
     <section 
      className="Banner IBanner position-relative"
      style={restaurant.background_image ? { backgroundImage: `url(${restaurant.background_image})` } : {}}
     > 
      <div className="PageHead">
        <h1 className="text-uppercase text-white"> {restaurant.title} </h1>
        <span className="text-white">{restaurant.subtitle}</span>
        <ol className="breadcrumb d-block">
          <li className="breadcrumb-item"> 
            <Link to="/">Home</Link> 
          </li>
          <li className="breadcrumb-item active"> 
            <Link to={`/restaurant/${restaurant.slug}/`}> {restaurant.title} </Link> 
          </li>
        </ol>
      </div>
    </section>
    )
}

export default RestaurantDetailBreadCrumb;