import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

function RestaurantDetailTabs(props)
{
    const { exampleProp } = props;
    const history = useHistory();
    const [restaurant, setRestaurant] = useState([]);
 
    useEffect(() => {
      let isMounted = true;
      const restaurant_slug = exampleProp.match.params.slug;
      axios.get(`/api/restaurant/${restaurant_slug}`).then(res=>{
          if(isMounted)
          {
              if(res.data.status === 200)
              {
                  setRestaurant(res.data.restaurant);
              }
          }
      });

      return () => {
          isMounted = false
      };
    }, [exampleProp.match.params.slug, history]);

    return (
     <div className="DetailTab mt-5">
    <nav>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {restaurant.content && (
          <button 
            className="nav-link active" 
            id="nav-description-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#nav-description" 
            type="button" 
            role="tab" 
            aria-controls="nav-description" 
            aria-selected="true">
              <i className="fa-solid fa-circle-info"></i> 
              <span className="m-2">Description</span>
          </button>
        )}

        {restaurant.location && (
          <button 
            className="nav-link" id="nav-location-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#nav-location" 
            type="button" 
            role="tab" 
            aria-controls="nav-location" 
            aria-selected="false">
              <i className="fa-solid fa-map"></i>
              <span className="m-2">Location</span>
          </button>
        )}

     
      </div>
    </nav>
    <div className="tab-content" id="nav-tabContent">
      {restaurant.content && (
        <div 
          className="tab-pane fade show active" 
          id="nav-description" 
          role="tabpanel" 
          aria-labelledby="nav-description-tab" 
          tabIndex="0">
          <div className="Info">
          {restaurant.menu_attachement && (
            <div className="mb-5">
              <a 
              className="restaurant-menu" 
              href={restaurant.menu_attachement} 
              target="_blank" rel="noreferrer">See Menu</a>
            </div>
          )}

          {restaurant.content && (
            <div>{parse(restaurant.content)}</div>
          )}
          {(restaurant.opening_time || restaurant.closing_time) && (
            <p>
              <strong>Opening Hours</strong> <br />
              Opens {restaurant.opening_time} until {restaurant.closing_time} 
            </p>
          )}
          
          </div>
        </div>
      )}
      
      {restaurant.location && (
        <div 
          className="tab-pane fade" 
          id="nav-location" 
          role="tabpanel" 
          aria-labelledby="nav-location-tab" 
          tabIndex="0">
            <div className="Location">
              {restaurant.location && (
                <div>{parse(restaurant.location)}</div>
              )}
            
              {restaurant.map && (
                <div className="mt-5">{parse(restaurant.map)}</div>
              )}
            </div>
        </div>
      )}
    </div>
  </div>
    )
}

export default RestaurantDetailTabs;