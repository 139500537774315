import React, {useEffect, useState} from 'react';
import axios from 'axios';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment'; // Add this import
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns'; // Import the format function

function FastTrackEditDetailContent(props)
{
    const { exampleProp }                           = props;
    const history                                   = useHistory();
    const [product, setFastTrack]                   = useState([]);
    const [cartItem, setCartItem]                   = useState('');
    const [productName, setProductName]             = useState('');
    const [productImage, setProductImage]           = useState('');
    const [productChildPrice, setProductChildPrice] = useState(0);
    const [productAdultPrice, setProductAdultPrice] = useState(0);
    const [productSlug, setProductSlug]             = useState('');
    const [adultCount, setAdultCount]               = useState(1);
    const [childCount, setChildCount]               = useState(0);
    const [leadPassengerName, setLeadPassengerName] = useState('');
    const [mobileNumber, setMobileNumber]           = useState('');
    const [airline, setAirline]                     = useState('');
    const [flightNo, setFlightNo]                   = useState('');
    const [departureDate, setDepartureDate]         = useState('');
    const [departureTime, setDepartureTime]         = useState("12:00 PM"); // Set initial time to 12:00 PM
    const [errorlist, setError]                     = useState([]);

    useEffect(() => {
        let isMounted             = true;
        const product_slug        = exampleProp.match.params.slug;
        const cartItemFromStorage = localStorage.getItem('cartItem');
        if (cartItemFromStorage) {
          try {
            const parsedCartItem = JSON.parse(cartItemFromStorage);
            setCartItem(parsedCartItem);
          } catch (error) {
            console.error('Error parsing cart item:', error);
          }
        }

        axios.get(`/api/product/${product_slug}`).then(res=>{
            if(isMounted)
            {
              if(res.data.status === 200)
              {
                setFastTrack(res.data.product);
                setProductName(res.data.product.title);
                setProductImage(res.data.product.featured_image);
                setProductAdultPrice(res.data.product.adult_price);
                setProductChildPrice(res.data.product.child_price);
                setProductSlug(res.data.product.slug);
                
                setAdultCount(cartItem.adultCount);
                setAdultCount(cartItem.childCount);
                setLeadPassengerName(cartItem.leadPassengerName);
                setMobileNumber(cartItem.mobileNumber);
                setAirline(cartItem.airline);
                setFlightNo(cartItem.flightNo);
              }
            }
        });
        

       
        return () => {
            isMounted = false
        };
    }, [exampleProp.match.params.slug, history]);

    // New function to handle date range selection
    const handleDateChange = (date) => {
      setDepartureDate(date);
    };

    const handleFormSubmit = (event) => {
      event.preventDefault();
      let totalChildPrice = parseFloat(productChildPrice) * parseFloat(childCount);
      let totalAdultPrice = parseFloat(productAdultPrice) * parseFloat(adultCount);
      let totalPriceCal   = totalChildPrice + totalAdultPrice;
      let totalPrice      = totalPriceCal.toFixed(2); // This will round the number to 2 decimal places
      let departureData   = format(departureDate, 'dd/MM/yyyy');
      const item = {
        productName,
        productImage,
        productChildPrice,
        productAdultPrice,
        productSlug,
        adultCount,
        childCount,
        leadPassengerName,
        mobileNumber,
        airline,
        flightNo,
        departureData,
        departureTime,
        totalPrice,
      };
      axios.post(`/api/product/cart/validation`, item).then(res=>{
          if(res.data.status === 200)
          {
            localStorage.setItem('cartItem', JSON.stringify(item));
            history.push('/cart');
          }
          else if(res.data.status === 422)
          {
              setError(res.data.errors);
          }
      });
    };

    return (
      <section className="ListSectinoDetail ProductDetail">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mt-5">
              {product.featured_image && (
                <figure>
                  <img src={product.featured_image} alt={product.title} className="img-fluid w-100" />
                </figure>
              )}
            </div>
            <div className="col-md-5 mt-5">
              <div className="RightCol">
                <h2 className="ProductTitle">{product.title}</h2>
                <span className="Cost">£{product.adult_price} per person (half price for child) </span>
                <div className="FormHolder">
                  <form onSubmit={handleFormSubmit}>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>ADULT (AGES 12 AND ABOVE)</label>
                      <select className="form-select" value={adultCount} onChange={(e) => setAdultCount(parseInt(e.target.value))}>
                        {[...Array(10).keys()].map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </select>
                      {errorlist.adultCount && (
                        <small className="text-danger">{errorlist.adultCount}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"> CHILD (AGES 2-12)</label>
                      <select className="form-select" value={childCount} onChange={(e) => setChildCount(parseInt(e.target.value))}>
                        {[...Array(10).keys()].map((value) => (
                          <option key={value} value={value}>{value}</option>
                        ))}
                      </select>
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>LEAD PASSENGER FULL NAME</label>
                      <input type="text" className="form-control" value={leadPassengerName} onChange={(e) => setLeadPassengerName(e.target.value)} />
                      {errorlist.leadPassengerName && (
                        <small className="text-danger">{errorlist.leadPassengerName}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>MOBILE NUMBER (INCL. COUNTRY CODE)</label>
                      <input type="text" className="form-control" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                      {errorlist.mobileNumber && (
                        <small className="text-danger">{errorlist.mobileNumber}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>AIRLINE</label>
                      <input type="text" className="form-control" value={airline} onChange={(e) => setAirline(e.target.value)} />
                      {errorlist.airline && (
                        <small className="text-danger">{errorlist.airline}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>FLIGHT NO.</label>
                      <input type="text" className="form-control" value={flightNo} onChange={(e) => setFlightNo(e.target.value)} />
                      {errorlist.flightNo && (
                        <small className="text-danger">{errorlist.flightNo}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>Arrival DATE</label>
                      <DatePicker
                        selected={departureDate}
                        onChange={handleDateChange}
                        className="form-control"
                        placeholderText="Date*"
                        autoComplete="off"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                      />
                      {errorlist.departureDate && (
                        <small className="text-danger">{errorlist.departureDate}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <label className="form-label"><span>*</span>Arrival TIME</label>
                       <TimePicker 
                          use12Hours={true}
                          showSecond={false}
                          className="form-control"
                          placeholder="Select Time"
                          onChange={(time) => setDepartureTime(time && time.format('h:mm A'))} // Update state with formatted time
                      />

                      {errorlist.departureTime && (
                        <small className="text-danger">{errorlist.departureTime}</small>
                      )}
                    </div>
                    <div className="FormWrap mt-3">
                      <button type="submit" className="btn btn-warning BtnOrange">Add To Basket</button>
                    </div>
                  </form>
                  <div className="product_meta mt-3">
                    <span className="posted_in">Category: <span className="text-orange">{product.category_name}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {product.content && (
          <div className="row">
            <div className="col-md-12">
              <div className="DetailTab mt-5">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button className="nav-link active" id="nav-description-tab" data-bs-toggle="tab" data-bs-target="#nav-description" type="button" role="tab" aria-controls="nav-description" aria-selected="true"><i className="fa-solid fa-circle-info"></i> <span>Description</span></button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-description" role="tabpanel" aria-labelledby="nav-description-tab" tabindex="0">
                    <div className="Info">
                      {parse(product.content)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </section>
    )
}

export default FastTrackEditDetailContent;