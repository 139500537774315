import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PageDetailBreadCrumb from './PageDetails/PageDetailBreadCrumb';
import PageDetailContent from './PageDetails/PageDetailContent';
import PageGallery from './PageDetails/PageGallery';
import PageForm from './PageDetails/PageForm';
import { Helmet } from 'react-helmet';

function PageDetail(props)
{
    const history = useHistory();
    const [page, setPage] = useState([]);
 
    useEffect(() => {
        let isMounted = true;
        const page_slug = props.match.params.page_slug;
        axios.get(`/api/page/detail/${page_slug}`).then(res=>{
            if(isMounted)
            {
                if(res.data.status === 200)
                {
                    setPage(res.data.page);
                }
            }
        });

        return () => {
            isMounted = false
        };
    }, [props.match.params.page_slug, history]);
    
    return (
        <div>
           <Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`Marrakech Concierge | Luxury Tailor-Made Holidays in Marrakech - ${page.title}`}</title>
            <meta name="description" content={page.meta_keywords} />
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
            <link rel="canonical" href="https://marrakechconcierge.co.uk/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={page.meta_title} />
            <meta property="og:description" content={page.meta_keywords} />
            <meta property="og:url" content={`https://marrakechconcierge.co.uk/page/${page.slug}`} />
            <meta property="og:site_name" content="Marrakech Concierge | Luxury Tailor-Made Holidays in Marrakech" />
            <meta property="og:image" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:secure_url" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={page.meta_title} />
            <meta property="og:image:type" content="image/jpg" />
            <link rel="apple-touch-icon"  href="https://marrakechconcierge.co.uk/marra/uploads/1707660811marrakech-logo-1.png" />
        </Helmet>
         	<PageDetailBreadCrumb exampleProp={props} />
            <section className="PageDetailSection mt-10">
              <div className="container">
                <div className="row">
                    <PageDetailContent exampleProp={props} />
                    <PageGallery exampleProp={props} />
                     {props.match.params.page_slug === 'transport-excursions' && (
                        <PageForm exampleProp={props} />
                     )}
                </div>
              </div>
            </section>
        </div>
    )
}

export default PageDetail;