import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VillaBanner from './VillaSection/VillaBanner';
import VillaContent from './VillaSection/VillaContent';
import { Helmet } from 'react-helmet';

function Villas() {
	const [setting, setSetting] = useState([]);

    useEffect(() => {
        let isMounted = true;

        axios.get(`/api/setting`).then(res=>{
            if(isMounted)
            {
                if(res.status === 200)
                {
                    setSetting(res.data.setting)
                }
            }
        });

        return () => {
            isMounted = false
        };

    }, []);
    return  (
    <div>
    	<Helmet>
            <meta charset="utf-8" />
            <link rel="icon" href={setting.favicon} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`Villas - ${setting.site_title}`}</title>
            <meta name="description" content={setting.meta_keywords} />
            <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
            <link rel="canonical" href="https://marrakechconcierge.co.uk/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={setting.meta_title} />
            <meta property="og:description" content={setting.meta_keywords} />
            <meta property="og:url" content="https://marrakechconcierge.co.uk/" />
            <meta property="og:site_name" content={setting.site_title} />
            <meta property="og:image" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:secure_url" content="https://marrakechconcierge.co.uk/marra/uploads/1710851764marrakech_concierge-6.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={setting.meta_title} />
            <meta property="og:image:type" content="image/jpg" />
            <link rel="apple-touch-icon"  href={setting.favicon} />
        </Helmet>
        <VillaBanner />
        <VillaContent />
    </div>
    )
}

export default Villas;
